// Product Button
// ----------------------------------------------------------------

$product-button-border-color: #e6e6e6 !default;
$product-button-border-radius: 10px !default;
$product-button-background-color-1: #fff !default;
$product-button-background-color-2: #f7f7f7 !default;
$product-button-padding: .6em !default;

.product-button {
  color: palette_new(teal);
  font-family: $heading-font;
  border-radius: $product-button-border-radius;
  border: 1px solid $product-button-border-color;
  background: linear-gradient($product-button-background-color-1, $product-button-background-color-2);
  text-align: center;
  overflow: hidden;
  text-decoration: none;
  padding: $product-button-padding;
  transition: all .2s;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    border-color: palette_new(teal);
  }

  .heading-cute {
    font-size: 3em;
    margin: 0;
    color: palette_new(teal);
  }
  .heading-quiet {
    color: $quiet-font-color;
    font-weight: bold;
    font-size: .8em;
  }
}

.product-button-horizontal {
  flex-direction: row;
  justify-content: center;

  img {
    margin: 0 .5em;
  }
}