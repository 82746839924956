// Mixins
// ----------------------------------------------------------------

@mixin container {
  max-width: 1170px;
  margin-inline: auto;
  padding: 0 1em;
}

@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context) * 1em;
}

@mixin leader($lines: 1, $context: $base-font-size) {
  margin-top: em($lines * $base-line-height, $context);
}

@mixin padding-leader($lines: 1, $context: $base-font-size) {
  padding-top: em($lines * $base-line-height, $context);
}

@mixin trailer($lines: 1, $context: $base-font-size) {
  margin-bottom: em($lines * $base-line-height, $context);
}

@mixin padding-trailer($lines: 1, $context: $base-font-size) {
  padding-bottom: em($lines * $base-line-height, $context);
}

@mixin rhythm($leader: 0, $padding-leader: 0, $padding-trailer: 0, $trailer: 0, $context: $base-font-size) {
  @include leader($leader, $context);
  @include padding-leader($padding-leader, $context);
  @include padding-trailer($padding-trailer, $context);
  @include trailer($trailer, $context);
}

@mixin clearfix {
  overflow: hidden;
}

@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

@mixin ellipsis ($max-width: 100%){
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hide-text {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
}


// Links
@mixin link-colors($link,$hover) {
  color: $link;
  &:hover {
    color: $hover;
  }
}

@mixin unstyled-link-with-hover() {
  @include unstyled-link;
  &:hover, &:focus {
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;
  }
}

@mixin unstyled-link {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  &:active, &:focus {
    outline: none;
  }
}


// Lists
@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    padding: 0;
  }
}

@mixin pipe-nav-list($padding: 12px, $direction: left) {
  @include horizontal-list-container;
  li {
    @include horizontal-list-item($padding, $direction);
    border-right: 1px solid;
    border-color: rgba(255,255,255,.3);
    padding: 0 $padding;
    &:last-child, &.last {
      border-right: none;
    }
    a {
      display: inline-block;
    }
  }
}

@mixin comma-separated-list() {
  @include delimited-list(', ');
  li { background: none; }
}

@mixin unstyled-list() {
  @include list-reset;
  li {
    background: none;
  }
}

@mixin horizontal-list($padding: 0) {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;

  li {
    @if $padding {
      padding: 0 $padding;
    }

    &:first-child {
      padding-left: 0;
    }
    
    &:last-child {
      padding-right: 0;
    }
  }
}