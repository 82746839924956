// Buttons

$default-button-bg:         #ddd !default;
$default-button-text-color: #333 !default;



@mixin button-colors($bg_color, $text_color: $default-button-text-color, $bg_hover: '') {
  background-color: $bg_color;
  color: $text_color;

  &:hover {
    color: $text_color;
    @if ($bg_hover != '') {
      background-color: $bg_hover;
    } @else {
      background-color: saturate(darken($bg_color, 10), 10);
    }
  }
}

@mixin button($bg_color: $default-button-bg, $text_color: $default-button-text-color, $bg_hover: '') {
  @include button-colors($bg_color, $text_color, $bg_hover);
  border-radius: $default-border-radius;
  transition: all .25s;
  font-family: $soft-font-bold;
  font-size: 0.7 * $base-font-size;
  line-height: 1.6;
  padding: .5em 2em;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: none;
  text-transform: uppercase;

  @include breakpoint($med-lg) {
    font-size: 0.88 * $base-font-size;
  }
}

.button,
input[type=button] {
  @include button($default-button-bg);
}

.button-simple {
  @extend .button;
  text-transform: uppercase;
  background-image: none;
  border: none;
}

.button-green {
  @extend .button;
  @include button-colors(palette(green), white);
}

.button-purple {
  @extend .button;
  @include button-colors(palette(purple), white);
}

.button-orange {
  @extend .button;
  @include button-colors(palette(orange), white);
}

.button-blue {
  @extend .button;
  @include button-colors(palette(blue), white);
}

.button-teal {
  @include button-colors(palette_new(teal), white);
}

.button-white {
  @include button-colors(white, palette_new(teal), white);
  border: 1px solid palette_new(teal);

  &:hover {
    color: palette_new(purple);
    border-color: palette_new(purple);
  }
}
.button-white-red {
  @include button-colors(white, #900, white);
  border: 1px solid #900;

  &:hover {
    color: #f00;
    border-color: #f00;
  }
}

.button-quiet {
  @extend .button;
  @include button-colors(#e3eef0, #6192a2);
}

.button-med {
  @extend .button;
  font-size: 19px;
}

.button-large {
  @extend .button;
  font-size: 22px;
  white-space: normal;
}

.button-small {
  @extend .button;
  font-size: 13px;
  padding: .25em 2em;
}

.button-full {
  display: block;
  width: 100%;
}

.button-disabled,
.button-disabled:hover,
.button[disabled],
.button[disabled]:hover {
  background-color: #c9d0d1;
  background-image: none;
  cursor: default;
  color: #575757 !important;
  text-shadow: none;
  cursor: default;
}

// Blank, resets button with no styles
.button-blank {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.button.has-shadow {
  box-shadow: .25em .25em 0 rgba(0,0,0,.2);
}
.button.has-image,
.button.has-icon {
  display: flex;
  align-items: center;
}

// Button that looks like a link
.link-button {
  -webkit-appearance: none;
  border: none;
  background: transparent;
  font-family: $base-font-family;
  font-size: 1rem;
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
  text-transform: initial;

  &:hover {
    background: none;
    color: darken($link-color, 10);
  }
}

// Has a right-chevron after the text
.button-chevron {
  &::after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 10px;
    margin-left: .5em;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.82 9.942L6.612 5.15a.217.217 0 000-.3L1.82.058A.244.244 0 001.67 0a.244.244 0 00-.15.058L.06 1.516a.217.217 0 000 .301L3.245 5 .06 8.183a.217.217 0 000 .3l1.458 1.46c.081.08.22.08.301 0z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    transition: all .2s;
  }
  &:hover::after {
    transform: translateX(.25em);
  }
}


// Product Types
.button-language-arts-programs {
  @extend .button-green;
}
.button-home-study-courses {
  @extend .button-orange;
}
.button-lifestyle-tools {
  @extend .button-purple;
}

// loading
@keyframes spin {
  from { transform: rotate(0deg); }
  to   { transform: rotate(359deg); }
}
.button.loading {
  position: relative;
  padding-right: 2.75em;

  &:after {
    content: url(/images/svg-icons-source/spinner.svg);
    position: absolute;
    top: 50%;
    right: 8px;
    width: 22px;
    height: 22px;
    margin-top: -11px;
    transform-origin: 50% 50%;
    animation: spin .85s linear infinite;
  }
}


// Persona Toggle Buttons
.persona-toggle-button {
  border: 2px solid palette_new(grey, light);
  border-radius: 6px;
  background-color: white;
  font-family: $heading-font-cute;
  font-size: 1.75em;
  color: #a6babf;
  padding: .125em .5em;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    background-color: $hover-color;
    color: palette_new(teal);
  }

  &:focus {
    outline: none;
  }

  > img {
    opacity: 0;
    max-height: 1px;
    position: relative;
    top: .5em;
    transition: all .2s;
  }

  &.mixitup-control-active,
  &.active,
  .active & {
    color: palette_new(teal);
    background-color: #fff;
    transform: scale(1.2);

    > img {
      opacity: 1;
      max-height: 3em;
      margin-top: -2em;
      margin-bottom: -.6em;
      position: relative;
      top: 0;
    }
  }
}

.logo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #FFFFFF, #EDEDED);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.26);
  border-radius: .2em;
  transition: all .2s;
  border: 1px solid #fff;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(to bottom, #FFFFFF, #ddd);
    border: 1px solid palette_new(teal);
    box-shadow: 0 0 4px 0 palette_new(teal);
  }
}