// Panels
// ----------------------------------------------------------------

$panel-border-radius: 6px !default;
$panel-border-color: #c8dee1 !default;
$panel-padding: 1.75rem !default;

.panel {
  @include trailer;
  border-radius: $panel-border-radius;
  background-color: white;
  padding: $panel-padding;
  border: 1px solid $panel-border-color;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.panel-header {
  margin: -1 * $panel-padding;
  margin-bottom: $panel-padding*.5;
  position: relative;

  .heading-overlay {
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255,255,255,.8);
    padding: .5em $panel-padding;
    margin: 0;
    width: 100%;
    line-height: 1.2;
    color: #2f2f2f;
  }
}

.panel-header-image {
  width: 100%;
  height: 20em !important; // override default responsive image settings
  object-fit: cover;
  vertical-align: middle; // avoid extra pixels under image
}

.panel-footer {
  margin-top: auto;
}

.panel-tag {
  position: absolute;
  top: 0;
  left: $panel-padding;
  background-color: #4390A8;
  color: #fff;
  text-transform: uppercase;
  padding: .25em 1em;
  font-weight: 500;
  font-size: .9em;
}

.panel-shadow {
  border: none;
  box-shadow: rgba(0,0,0,.3) 0 2px 9px;
}