// My Lists
// ----------------------------------------------------------------

$mylists-float-bp: 980px;

:root {
  --mylists-border-radius: .5rem;
  --mylists-float-top: 14rem;
  --mylists-float-right: 1rem;
  --mylists-float-open-width: 26rem;
  --mylists-bg-color: #fff3ff;
}

// General dialog/popover transitions
[popover], dialog, ::backdrop {
  transition: 
    display .5s allow-discrete, 
    overlay .5s allow-discrete, 
    opacity .5s;
  opacity: 0;
}

/* IN */
:popover-open,
:popover-open::backdrop,
[open],
[open]::backdrop {
  opacity: 1;
}

/* OUT */
@starting-style {
  :popover-open,
  :popover-open::backdrop,
  [open],
  [open]::backdrop {
    opacity: 0;
  }

  @include breakpoint($mylists-float-bp) {
    .mylists-dialog[open] {
      right: calc(-1 * var(--mylists-float-open-width));
      top: var(--mylists-float-top);
      bottom: auto;
      left: auto;
    }
  }
}

.mylists-dialog {
  --active-color: #6e246b;
  --highlight-color: #c53b95;

  display: block;
  opacity: 1;
  height: 3em;
  width: 100%;
  background-color: var(--mylists-bg-color);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  z-index: z-index(mylists);
  box-shadow: 0 0 12px rgba(0,0,0,.2);
  border: none;
  margin: 0;
  padding: 0;
  max-width: none;
  overflow: hidden;
  transition: height .25s ease-in-out;

  &[open] {
    height: 75vh;

    &::backdrop {
      opacity: 1;
    }
  }

  @include breakpoint($mylists-float-bp) {
    right: calc(-1 * var(--mylists-float-open-width));
    top: var(--mylists-float-top);
    bottom: auto;
    left: auto;
    width: var(--mylists-float-open-width);
    height: auto;
    border-radius: var(--mylists-border-radius);
    transition: right .4s ease-in-out;

    &[open] {
      right: var(--mylists-float-right);
      height: auto;
    }
  }

  &::backdrop {
    opacity: 0;
    background-color: rgba(0,0,0,.8);
    transition: opacity .25s;
  }
}
@starting-style {
  .mylists-dialog[open],
  .mylists-dialog[open]::backdrop {
    opacity: 0;
  }
}


.mylists {
  padding: .5em .75em .75em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mylists-actions {
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :is(.button-mylists, .button-close) {
    margin-left: auto;
  }

  .button-mylists { display: block; }
  .button-close   { display: none; }
}

.mylists-dialog[open] .mylists-actions {
  .button-mylists { display: none; }
  .button-close   { display: block; }
}

.mylists-tabs {
  flex: 0;

  ul[role=tablist] {
    justify-content: center;
  }

  a {
    text-transform: none;
    font-family: $soft-font-bold;
    color: var(--active-color);
    opacity: .4;
    border: none;
    background: none;
  }

  .active a {
    color: var(--active-color);
    opacity: 1;
    background: none;
    border: none;
    text-decoration: underline 2px var(--highlight-color);
    text-underline-offset: .3em;
    box-shadow: none;
  }
}
.mylists-tabpanels {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;

  .tab-pane {
    height: 100%;
  }
}
.mylists-listgroup {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #ebdfeb;
  border-radius: .5em;

  .my-list-item {
    padding: .75em;

    & + .my-list-item {
      border-top: 1px solid #ebdfeb;
      margin-top: 0;
    }

    a {
      text-decoration: none;
    }
  }
}

// MyLists Save/To-Do/Done Buttons
// ----------------------------------------------------------------
.list-save-form *[type=submit]{
  display: none;
}

.icon-button-group {
  display: flex;
  align-items: center;
  gap: .5em;
}

.icon-button {
  --size: .85em;
  font-size: 1.882352941rem; // 32px (32/17 base font size)

  @include breakpoint($mylists-float-bp) {
    --size: 1em;
  }

  label {
    width: var(--size);
    height: var(--size);
    background-color: #fff;
    border: 1px solid #f2bbdf;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 1em;
    // fill: palette_new(purple);
    fill: palette_new(purple);
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: palette_new(purple);
    }
  }

  // Hide text in span
  span {
    height: 0; 
    width: 0;
    overflow: hidden;
    position: absolute;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  input[type=checkbox] {
    display: none;
  }

  // Checked
  input[type=checkbox]:checked ~ label {
    background-color: palette_new(purple);
    border-color: palette_new(purple);
    fill: #fff;
  }
}

// My Lists Float
// ----------------------------------------------------------------
.mylists-float {
  --active-color: #6e246b;
  --highlight-color: #c53b95;
  --width: 5em;

  position: fixed;
  z-index: z-index(mylists);
  right: var(--mylists-float-right);
  top: var(--mylists-float-top);
  width: var(--width);
  display: none;
  background-color: var(--mylists-bg-color);
  border-radius: var(--mylists-border-radius);
  padding: 1.5em;
  box-shadow: 0 2px 8px rgba(0,0,0,.2);
  transition: all .4s ease-in-out;

  @include breakpoint($mylists-float-bp) {
    display: block;
  }

  .mylists-dialog[open]+& {
    right: calc(-1.05 * var(--width));
  }

  .mylists-actions,
  .icon-button-group {
    flex-direction: column;
  }

  .icon-button {
    --size: 1.25em;
  }

  .button-mylists {
    border: none;
    background: none;
    width: 100%;
    margin: 1em 0 0;
    font-size: 1.2em;
    opacity: .5;
    transition: opacity .2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}