// ================================================================
// Marketing Page
// ================================================================

.banner-blh-title {
  h1 {
    font-size: 4.5em;

    @include breakpoint($med-lg) {
      font-size: 6em;
    }
  }
  .banner-img {
    display: none;
    @include breakpoint($med) {
      display: block;
    }
  }
}
.banner-blh-marketing {
  background-color: #009899;
  color: #fff;
  position: relative;
  padding-top: 1em;

  .banner-content {
    img { display: block; }
  }
  .banner-image {
    position: absolute;
    left: 50%;
    bottom: 0;
    display: none;
  }

  @include breakpoint($med-lg) {
    .banner-content { padding-bottom: 2em; }
    .banner-blh-cta .banner-content { width: 47%; padding-bottom: 0; }
    .banner-image { display: block; width: 40%; }
  }
  @include breakpoint($lg) {
    .banner-content { width: 47%; padding-bottom: 0; }
    .banner-image { width: 615px; left: 47%; }
  }
}
.banner-blh-cta {
  background-color: #008384;
  padding: 1em 0;

  .button {
    color: palette_new(purple);
  }

  a:not(.button) {
    color: inherit;
  }
}


.blh-marketing {
  .heading-cute.has-flourish-dot {
    font-size: 4.5em;
    margin: 0;
  
    @include breakpoint($med-lg) {
      font-size: 6em;
    }
  }
}

// What You Get
// ----------------------------------------------------------------
.what-you-get .grid {
  --grid-item-width: 125px;  
  --gap: 1em;
}
.what-you-get .grid > a {
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.2;
  padding: 1em;
}
.what-you-get .icon-circle {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-bottom: .5em;
}
@each $section, $color in $blh-palette {
  .icon-circle.#{$section} { background-color: $color; }
}

// Features Grid
// add to .content-features-grid
.blh-features-grid {
  @include breakpoint($lg) {
    // First and fourth item is double-width
    > *:nth-child(3n - 2) {
      grid-column: 1/3;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4em;
    }
    // The fourth item should be reversed horizontally (image first, then content)
    > *:nth-child(4) > img {
      order: -1;
    }
  }

  // Underline and BG Circle Colors
  @each $section, $color in $blh-palette {
    ##{$section} .bg-circle { fill: $color; }
    ##{$section} .squiggle-underline { fill: $color; }
  }

  > :nth-child(1) .bg-circle { top: 50%; left: 60%; transform: scale(3); }
}

// Get BLH
.stripe-get-blh {
  margin-top: 3em;
  
  .blh-logo {
    position: relative;
    top: -8em;
    display: block;
    margin: 0 auto -8em;
    max-width: 30%;
  }
  .panel {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0 0 1.75em;
    overflow: visible;

    &:hover {
      border-color: palette_new(teal);
      background-color: palette_new(blue, x-light);
    }

    img {
      display: block;
      margin: -1.5em auto 0;
      max-width: 96%;
    }
  }
  .button {
    max-width: 90%;
    margin: 0 auto;
  }
}

// Testimonials
.stripe-blh-testimonials {
  background: #f9f9fa url(/images/pages/blh/bg-swash-large.svg) 50% 50% no-repeat;

  .heading-image {
    display: block;
    margin: 0 auto;
  }
  .testimonial-box {
    width: 27rem;
  }
  .carousel-media {
    gap: 3rem;
  }
  .carousel-control {
    border: none;
  }
  .carousel-item {
    scroll-snap-align: start;
  }
}