// Landing Page : Download your Freewriting lessons!
// ----------------------------------------------------------------

a.more:before {
  content: '\00BB';
  display: inline-block;
  padding: 0 .5em;
  color: #999;
};

.intro .photo-frame {
  transform: rotate(3deg);
  float: right;
  margin-right: -10%;
  margin-left: 2em;
  margin-bottom: 2em;
}

.whimsy {
  transform: rotate(3deg);
}

body.landing_page .get-writers-jungle {
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 3px;
  background: linear-gradient(#ffe1c7, #ffffff);
  font-size: 1.4em;
  line-height: 1.25em;
  margin-top: 3.33333em;
  margin-bottom: 1.66667em;
  transition: all 0.2s;
  display: block;
  padding: 1em 2em 1em 6.5em;
  border: 1px solid #ffc6a6;
  color: #c7591d;
  text-decoration: none;
  position: relative;
}
body.landing_page .get-writers-jungle:hover {
  transform: scale(1.03, 1.03);
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 5px;
  border-bottom: none;
}
body.landing_page .get-writers-jungle img {
  position: absolute;
  left: 1em;
  top: -1em;
}