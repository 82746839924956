// Header
// ----------------------------------------------------------------

$banner-image-height: 70px;

.header-main {
  background-color: #fff;
  padding: .5em 0;
  border-block-start: 3px solid palette_new(green);
  border-block-end: 1px solid #c5a8c4;
  
  @include breakpoint($lg) {
    padding-block: 1.35em;
    border-block-end: 3px solid palette_new(purple);
  }

  > .container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 0 4rem;

    @include breakpoint($lg) {
      grid-template-columns: 1.1fr 4fr;
    }
  }

  .logo {
    grid-row: 1/2;
    grid-column: 1/2;
    max-width: 14rem;

    @include breakpoint(max-width $med) {
      max-width: 8.5rem;
    }
  }
  .nav-account {
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: start;
    justify-self: end;
    margin-top: -1.2em;
  }
  .nav-main {
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: center;
  }
  .mobile-nav-button {
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: center;
    justify-self: end;
  }
}

// Announcement Bar
// ----------------------------------------------------------------

.announcement-bar {
  background-color: palette_new(green);
  color: white;
  text-align: center;
  font-size: .91em;
  padding: .25em;
  display: none;

  @include breakpoint($med) {
    display: block;    
  }

  .button-simple {
    background-color: #9fd232;
    padding: .2em 0.5em;
    color: #485b1f;
    margin-left: 0.25em;
    line-height: 1.3;
    text-transform: none;
    font-size: 0.9em;

    &:hover {
      background-image: none;
      background-color: #dcf99d;
    }
  }

  a {
    color: #e3f4f5;
  }

  & + .header-main .mobile-nav-button {
    top: 65px;
  }
}


// Secondary Header
// ----------------------------------------------------------------

.header-secondary {
  @include breakpoint($lg) {
    box-shadow: rgba(0,0,0,.1) 0 8px 0;
    padding-top: .65rem;
    padding-bottom: .65rem;
    margin-bottom: 8px;
    background: palette(teal, dark);
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    
    // If followed by a banner of any kind, position it up to remove the bottom margin
    & + *[class*='banner'] {
      margin-top: -8px;
    }
  }
}

.banner {
  .back-button {
    border-radius: 4px;
    text-decoration: none;

    &:hover .back-button-icon { background-color: rgba(0, 0, 0, 0.35); }
  }
  .back-button-icon {
    width: 2.35em;
    height: 2.35em;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: .15em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .25s;
  }
}


// Title Banner
// ----------------------------------------------------------------
:where(.banner-title) { // low specificity to not override other classes
  background: #f5fbfc;
}
.banner-title {
  display: block;
  border-bottom: solid 1px rgba(150,198,213,0.48);
  margin-bottom: 1.5em;

  > .container {
    position: relative;
  }

  h1 {
    font-weight: 300;
    margin: 0 auto;
    padding: .75em 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint($med) {
      line-height: $banner-image-height;
    }
  }

  .banner-img {
    max-width: $banner-image-height;
    max-height: $banner-image-height;
    margin-right: .25em;
  }

  .icon-circle {
    margin-right: .25em;
  }

  .back-button {
    position: absolute;
    border-radius: 4px;
    left: .5rem;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #fff;

    &:hover .back-button-icon { background-color: rgba(67, 144, 168, 0.35); }
  }
  .back-button-icon {
    width: 2.35em;
    height: 2.35em;
    background-color: rgba(67, 144, 168, 0.2);
    fill: #4390A8;
    margin-right: .5em;
    border-radius: .15em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .25s;
  }
}
.banner-title-left h1 {
  justify-content: start;
  margin-left: 0;
  margin-right: 0;
}

// Handwriting Font
.banner-title-cute {
  h1 {
    font-family: $heading-font-cute;
    font-size: 3.5em;
    padding: .375em 0;

    @include breakpoint(600px) {
      font-size: 4.5em;
    }
  }
  .banner-img {
    margin-top: -.25em;
    margin-bottom: -.25em;
    max-width: 70px;
    max-height: 70px;
  }
}


// Banner Colors
.banner-subtitle {
  display: block;
  border-bottom: solid 1px rgba(150,198,213,0.48);
  margin-bottom: 1.5em;

  .media {
    margin-bottom: 1.5em;
  }

  h3 {
    font-size: 1.625em;
    color: #4a4a4a;
    font-weight: 300;
    margin-top: .5em;
    margin-bottom: .25em;
  }
}

// Banner Images/Items
@include breakpoint($lg) {
  .banner-item-move-up {
    margin-top: -6em; // cheat the banner item up into the title bar
  }
  .banner-item-pull-bottom {
    margin-bottom: -5em; // pull the bottom up so the banner item overlaps the bottom
  }
}


// Breadcrumbs
// ----------------------------------------------------------------

// .breadcrumbs {
//   display: none;

//   @include breakpoint($lg) {
//     display: block;
//   }
// }

// Content Header
// ----------------------------------------------------------------
.content-header {
  background-color: palette(teal, xx-light);
  border-bottom: 1px solid #c8dee1;
}