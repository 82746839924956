// Gallery
// ----------------------------------------------------------------

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .gallery-item {
    margin: 1em;
  }
}