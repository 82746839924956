.testimonial-box {
  background-color: #fff;
  border: 3px solid #ededee;
  border-radius: .5em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  blockquote {
    font-size: 1.1em;
    font-style: italic;
    color: palette(blue);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    &::before {
      content: '';
      display: block;
      width: 70px;
      height: 52px;
      background-image: url("data:image/svg+xml,%3Csvg width='70' height='52' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.8 1.48C8.84 7.78.92 20.74.92 35.5c0 5.58 2.88 16.2 14.4 16.2 9.54 0 14.58-5.94 14.4-13.32 0-5.76-3.96-11.52-11.16-11.52-.9.18-1.98.18-3.06.54 0-6.3 3.6-13.32 10.98-17.82 2.16-1.26 2.52-3.06 1.26-5.04l-.72-1.62C25.76.76 23.96.22 21.8 1.48Zm40.14 0C48.98 7.78 41.06 20.74 41.06 35.5c0 5.58 2.88 16.2 14.4 16.2 9.54 0 14.58-5.94 14.4-13.32 0-5.76-3.96-11.52-11.16-11.52-.9.18-1.98.18-3.06.54 0-6.3 3.6-13.32 10.98-17.82 2.16-1.26 2.52-3.06 1.26-5.04l-.72-1.62C65.9.76 64.1.22 61.94 1.48Z' fill='%2300B9BA' fill-rule='nonzero'/%3E%3C/svg%3E");
      margin-top: -3em;
    }
  }
  cite {
    font-weight: bold;
    display: block;
    text-align: center;
    font-size: 0.8em;
    font-style: normal;
  }
  .avatar-circle {
    margin-bottom: -4em;
  }
}

.testimonial-box-wide blockquote {
  position: relative;

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='70' height='52' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.8 1.48C8.84 7.78.92 20.74.92 35.5c0 5.58 2.88 16.2 14.4 16.2 9.54 0 14.58-5.94 14.4-13.32 0-5.76-3.96-11.52-11.16-11.52-.9.18-1.98.18-3.06.54 0-6.3 3.6-13.32 10.98-17.82 2.16-1.26 2.52-3.06 1.26-5.04l-.72-1.62C25.76.76 23.96.22 21.8 1.48Zm40.14 0C48.98 7.78 41.06 20.74 41.06 35.5c0 5.58 2.88 16.2 14.4 16.2 9.54 0 14.58-5.94 14.4-13.32 0-5.76-3.96-11.52-11.16-11.52-.9.18-1.98.18-3.06.54 0-6.3 3.6-13.32 10.98-17.82 2.16-1.26 2.52-3.06 1.26-5.04l-.72-1.62C65.9.76 64.1.22 61.94 1.48Z' fill='%23c53b95' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  @include breakpoint($lg) {
    padding-left: 90px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
    }
  }
}


.testimonial-box-colored {
  border-radius: 1em;
  padding: 2em;
  background-color: #00b9bb;
  color: #fff;
  font-family: $soft-font;
  font-size: 1.1em;
  display: flex;
  align-items: center;

  &:nth-child(4n-3) { background-color: palette_new(teal); }
  &:nth-child(4n-2) { background-color: palette_new(green); }
  &:nth-child(4n-1) { background-color: palette_new(purple); }
  &:nth-child(4n)   { background-color: palette_new(orange); }

  > blockquote {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: end;
    gap: 1em;
    margin: 0;
    width: 100%;
  }

  > blockquote::before {
    flex: 1 1 10%;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='70' height='52' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.8 1.48C8.84 7.78.92 20.74.92 35.5c0 5.58 2.88 16.2 14.4 16.2 9.54 0 14.58-5.94 14.4-13.32 0-5.76-3.96-11.52-11.16-11.52-.9.18-1.98.18-3.06.54 0-6.3 3.6-13.32 10.98-17.82 2.16-1.26 2.52-3.06 1.26-5.04l-.72-1.62C25.76.76 23.96.22 21.8 1.48Zm40.14 0C48.98 7.78 41.06 20.74 41.06 35.5c0 5.58 2.88 16.2 14.4 16.2 9.54 0 14.58-5.94 14.4-13.32 0-5.76-3.96-11.52-11.16-11.52-.9.18-1.98.18-3.06.54 0-6.3 3.6-13.32 10.98-17.82 2.16-1.26 2.52-3.06 1.26-5.04l-.72-1.62C65.9.76 64.1.22 61.94 1.48Z' fill='%23ffffff' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 0;
    opacity: .3;
    min-height: 52px;
  }

  blockquote > * {
    flex: 0 0 80%;
  }
  blockquote p {
    margin: 0;
  }
}
