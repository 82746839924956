// Preview Banner
// ----------------------------------------------------------------
.banner-hsa-preview {
  background-color: #E8E9E4;
  color: palette(purple);
  padding-top: 1rem;
  padding-bottom: 1rem;

  img {
    display: block;
    margin: 0 auto;
  }

  h1, h2, p {
    color: inherit;
    text-align: center;
  }
  p:last-child {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  small {
    color: palette(teal);
    opacity: .8;
  }

  .buttons {
    text-align: center;
  }

  .button {
    border: 1px solid palette(purple);
  }

  .button-secondary {
    background-color: rgba(255,255,255,.5);
    color: palette(purple);
  }

  @include breakpoint($lg) {
    background: #ECECEA url(/images/pages/hsa/hsa-banner-marketing.jpg) 100% 0/contain no-repeat;

    & > .container {
      display: flex;
      align-items: flex-start;
    }
    img {
      display: inline-block;
      margin: 0;
    }
    h1 {
      font-size: 3em;
      margin-top: .75em;
    }
    h1,
    h2,
    p {
      text-align: left;
    }
    .buttons {
      display: inline-block;
      text-align: center;
    }
  }

  .container {
    position: relative;
  }
}

// Panels
// ----------------------------------------------------------------
@include breakpoint($med-lg) {
  .hsa-preview-content-panels {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -18px;

    .panel {
      flex: 1;
      margin-left: 18px;
      flex: 0 1 calc(50% - 18px);
    }
  }
}


// HSA logo bg (potentially for checklist)
// ----------------------------------------------------------------
.hsa-bg {
  background: url(/images/products/homeschool-alliance.svg) -135px -100px/750px no-repeat,
              linear-gradient(#ffffff, #ecf6f4);
  padding-top: 10em;
  padding-bottom: 5em;
}


// Testimonials
// ----------------------------------------------------------------

@include breakpoint($med) {
  .testimonial {
    display: flex;
    align-items: flex-start;

    .image {
      flex: 2;
    }
    .content {
      flex: 5;
      padding-left: 5em;
    }
  }
}

.testimonial {
  h2 {
    color: #fff;
    font-size: 2em;
    margin-top: 0;
    margin-bottom: .5em;
  }

  img {
    border-radius: 50%;
    overflow: hidden;
    display: block;
    margin: 0 auto;
  }

  blockquote {
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      content: '\201C';
      color: rgba(255, 255, 255, 0.5);
      font-size: 6em;
      position: absolute;
      left: -.5em;
      top: -.5em;
    }
  }
}


// Sign UP
// ----------------------------------------------------------------
.signup {
  margin-bottom: 4rem;

  @include breakpoint($lg) {
    & > .container {
      display: flex;
      align-items: center;
    }

    p {
      flex: 1;
      margin-bottom: 0;
      padding: 0 1rem;
    }
  }

  p {
    line-height: 1.2;
  }

  a {
    color: #fff;
  }

  strong {
    text-transform: uppercase;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    font-size: 2.5em;
    font-weight: 400;
  }

  em,
  small {
    opacity: .8;
  }

  .button {
    background-color: #fff;
    color: palette(teal) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    width: 100%;
  }
}
