// Color Scheme #97d8ec
// ----------------------------------------------------------------


// Misc
// ----------------------------------------------------------------
.members-only-link {
  opacity: .7;
}


// Banner
// ----------------------------------------------------------------

body.homeschool-alliance {
  .banner-hsa-home {
    @include trailer(3.5);

    .content-narrow {
      position: relative;
    }
  }

  .hsa-banner {
    @include rhythm(.5,0,0,-3);
    border-radius: $default-border-radius;
    box-shadow: 0 2px 7px rgba(0,0,0,.5);
  }
}


// Broadcast
// ----------------------------------------------------------------

@keyframes pulse-glow {
  0%   { box-shadow: 0 0 14px 0 rgba(252, 243, 169, 1); }
  50% { box-shadow: 0 0 8px 0 rgba(252, 243, 169, 0); }
  100%   { box-shadow: 0 0 14px 0 rgba(252, 243, 169, 1); }
}

.broadcast-live .icon-circle {
  position: relative;
  animation: pulse-glow 2s infinite;
  background-color: #d71e13;
}








// Self Care Spa Signup
// ----------------------------------------------------------------

.hubspot-self-care-signup {
  input[type=submit] {
    font-size: 1em;
    margin-bottom: .5em;
  }
}


// Heading
// ----------------------------------------------------------------




// Community Conversations
// ----------------------------------------------------------------
$cc-breakpoint: 680px;

.cc-month + .cc-month {
  margin-top: 3em;
}

.cc-month-header,
.cc-category {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 2rem;
}
.cc-month-header .heading-cute {
  align-self: center;
  position: relative;
  top: -.125em;
}

@include breakpoint(max-width $cc-breakpoint) {
  .cc-category {
    display: block;
  }
  .cc-category-name {
    margin-bottom: 1em;
  }
}

.cc-category + .cc-category {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid $divider-color;
}

.cc-category-name h4 {
  color: darken($base-font-color, 15);
  font-weight: bold;
  font-size: 0.9em;
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: .25em;
    flex-shrink: 0;
  }
}

.cc-thread-list-item {
  display: flex;
  align-items: flex-start;

  time {
    flex: 0 0 6em;
    font-size: 0.8em;
    position: relative;
    top: .15em;
  }

  a {
    font-size: .9em
  }

  .badge {
    margin-left: 0;
  }
}

// "Controls" with search and sorting
.cc-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -.5rem;

  > * {
    flex: 1;
    margin: .5rem;
  }

  .search-form {
    flex: 2;
    margin-left: auto;
    margin-right: 0;
    text-align: right;
  }

  button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: .9em;
    justify-content: center;
    color: $link-color;
    font-family: $base-font-family;
    cursor: pointer;

    &:hover {
      color: darken($link-color, 10);
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='9' height='5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 5c.161 0 .302-.06.423-.178l3.902-3.816c.181-.178.222-.39.121-.636-.1-.247-.282-.37-.544-.37H.598C.336 0 .155.123.054.37c-.1.246-.06.458.121.636l3.902 3.816A.587.587 0 004.5 5z' fill='%234390A8' fill-rule='nonzero'/%3E%3C/svg%3E");
      margin-left: .5em;
      position: relative;
      top: -1px;
    }
  }
}

// Froala form
// set side to 16px to prevent iPhone zooming
.fr-box *[contenteditable=true] {
  font-size: 16px;
}