// Product Grid
// ----------------------------------------------------------------

.product-grid {
  @include unstyled-list;
  display: flex;
  flex-wrap: wrap;
  margin-left: -.5rem;
  margin-right: -.5rem;
  
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 1rem;
    margin-left: 0;
    margin-right: 0;

    @include breakpoint(550px) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
  }

  .product-grid-item {
    display: inline-block;
    margin: .5rem;
    flex: 1 0 160px;

    @include breakpoint(550px) {
      flex-basis: 240px;
    }

    @supports (display: grid) {
      margin: 0;
    }

    a {
      @include unstyled-link;
      font-size: 14px;
      display: block;
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

}