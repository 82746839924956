// Dropdowns
// ----------------------------------------------------------------
// original src: bootstrap

$dropdown-min-width:             160px !default;
$dropdown-padding-y:             5px !default;
$dropdown-margin-top:            2px !default;
$dropdown-bg:                    #fff !default;
$dropdown-border-color:          rgba(0,0,0,.1) !default;
$dropdown-border-width:          1px !default;
$dropdown-divider-bg:            #e5e5e5 !default;
$dropdown-box-shadow:            0 6px 12px rgba(0,0,0,.175) !default;

$dropdown-link-color:            $link-color !default;
$dropdown-link-hover-color:      darken($link-color, 5%) !default;
$dropdown-link-hover-bg:         #f5fbfc !default;

$dropdown-link-active-color:     #fff !default;
$dropdown-link-active-bg:        blue !default;

$dropdown-link-disabled-color:   #888 !default;

$dropdown-item-padding-x:        20px !default;

$dropdown-header-color:          #888 !default;

$zindex-dropdown-backdrop: 990 !default;
$zindex-dropdown:          1000 !default;


// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
  position: relative;
}

// .dropdown-toggle {
//   // Generate the caret automatically
//   &::after {
//     display: inline-block;
//     width: 0;
//     height: 0;
//     margin-left: $caret-width;
//     vertical-align: middle;
//     content: "";
//     border-top: $caret-width solid;
//     border-right: $caret-width solid transparent;
//     border-left: $caret-width solid transparent;
//   }

//   // Prevent the focus on the dropdown toggle when closing dropdowns
//   &:focus {
//     outline: 0;
//   }
// }

// .dropup {
//   .dropdown-toggle {
//     &::after {
//       border-top: 0;
//       border-bottom: $caret-width solid;
//     }
//   }
// }

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10000;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-margin-top 0 0; // override default ul
  font-size: 1em;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: 3px;
  box-shadow: $dropdown-box-shadow;
}


// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: 3px $dropdown-item-padding-x;
  clear: both;
  font-weight: normal;
  color: $dropdown-link-color;
  text-decoration: none;
  font-size: .9em;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background: none; // For `<button>`s
  border: 0; // For `<button>`s

  &:hover {
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
    text-decoration: none;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

.dropdown-menu a {
  @extend .dropdown-item;
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  right: 0;
  left: auto; // Reset the default from `.dropdown-menu`
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-dropdown-backdrop;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    content: "";
    border-top: 0;
    // border-bottom: $caret-width solid;
  }

  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: $dropdown-margin-top;
  }
}