$stack-space        : 1.5rem !default;
$stack-tight-space  : .75rem !default;
$stack-x-tight-space  : .5rem !default;
$stack-border-color : #e5f0f2 !default;
$stack-border-width : 1px !default;

// Vertically stacked elements with margin between each
.stack {
  --space: #{$stack-space};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stack > * {
  margin-top: 0;
  margin-bottom: 0;
} 

.stack > * + * {
  margin-top: var(--space);
}

// Recursive stacking applies margins between all descendants
.stack-recursive * + * {
  margin-top: var(--space);
}

// Tighter spacing
.stack-tight > * + * {
  --space: #{$stack-tight-space};
}

// Bordered stack has divider lines between
.stack-bordered > * + * {
  padding-top: var(--space);
  border-top: $stack-border-width solid $stack-border-color;
}
.stack-bordered.has-outer-borders {
  padding-top: var(--space);
  padding-bottom: var(--space);
  border-top: $stack-border-width solid $stack-border-color;
  border-bottom: $stack-border-width solid $stack-border-color;
}