// Getting Started Pages
// ----------------------------------------------------------------

// Index
// ----------------------------------------------------------------
.getting-started-video-stripe {
  @include rhythm(0,2,2,0);
  background: transparent url(/images/pages/getting-started/video-banner.jpg) 50% 50% / cover no-repeat;
  color: #0a2c36;
  overflow: hidden;
  margin-top: -1.5em;

  > .container {
    display: flex;
    flex-wrap: wrap;
  }
  .banner-content {
    flex: 4 1 300px;
    margin-right: 1rem;
  }
  .video {
    flex: 6 1 300px;
  }

  @include breakpoint($lg) {
    .video   { order: 2; }
  }

  .video-embed {
    margin-bottom: 0;
    box-shadow: $default-box-shadow;
    border-radius: $default-border-radius;
  }

  .button-simple {
    box-shadow: $default-box-shadow;
    font-size: 1em;
  }
}


// Icon navigation
// ----------------------------------------------------------------
.stripe-gs-nav {
  margin-top: -1.5em;

  a {
    @include rhythm(0,.5,.5,0);
    display: block;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .selected a { border-bottom: 5px solid palette(purple); }
}

.gs-nav {
  align-items: flex-start;
}
.gs-nav-icon {
  font-size: 3.5em;

  &.puzzle { width: 1.7em; }
}


// Badges
// ----------------------------------------------------------------
.writing-stage-badge {
  border: 1px solid #b8b8b8;
  box-shadow: rgba(0,0,0,.3) 0 4px 6px;
  width: 6em;
  height: 6em;
  background-color: #fff;
  border-radius: 100%;
  display: flex;

  svg {
    width: 3.5em;
    margin: auto;
  }
}

.stages-triangle,
.writing-triangle {
  .stage { opacity: .1; }

  &.beginning-writers .stage-beginning-writers,
  &.ages-5-8 .stage-beginning-writers,
  &.growing-writers .stage-growing-writers,
  &.emerging-writers .stage-emerging-writers,
  &.ages-9-10 .stage-growing-writers,
  &.middle-school-writers .stage-middle-school-writers,
  &.ages-11-12 .stage-middle-school-writers,
  &.high-school-writers .stage-high-school-writers,
  &.ages-13-14 .stage-high-school-writers,
  &.college-prep-writers .stage-college-prep-writers,
  &.ages-15-18 .stage-college-prep-writers,
  &.multiple-ages .stage { opacity: 1; }
}

.writing-stage .content-split-section {
  position: relative;

  .deep-flat-shadow {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  img {
    width: 85%;
  }
}
.deep-flat-shadow + .writing-stage-badge {
  position: absolute;
  top: 1em;
  left: 50%;
  margin-left: 32%;

  .content-split-reverse & {
    left: auto;
    right: 50%;
    margin-left: 0;
    margin-right: 32%;
  }
}


// Program Type
// ----------------------------------------------------------------

.program-type-image {
  z-index: 2;
  position: relative;
  text-align: center;

  .deep-flat-shadow {
    margin-top: 2em;
  }

  img {
    width: 85%;
  }

  .puzzle-piece {
    position: absolute;
    max-width: 40%;
    max-height: 170px;
    z-index: 10;
    top: 0;
    left: 50%;
    margin-left: -20%;

    @include breakpoint($lg) {
      top: 20%;
      left: 0;
      margin-left: 0;
    }
  }
}

.program-type-description {
  z-index: 1;
  border-radius: 8px;
  background-color: #e3f2ef;
  padding: 1em;

  .program-original-thought &         { background-color: #e3f2ef; }
  .program-mechanics-and-literature & { background-color: #e1f2f4; }
  .program-writing-projects &         { background-color: #e1f4fa; }
}

@include breakpoint($lg) {
  .program-original-thought         { background-image: linear-gradient(to right, #fff 55%, #e3f2ef 55%); }
  .program-mechanics-and-literature { background-image: linear-gradient(to right, #fff 55%, #e1f2f4 55%); }
  .program-writing-projects         { background-image: linear-gradient(to right, #fff 55%, #e1f4fa 55%); }

  .program-type > .container {
    display: grid;
    grid-template-columns: 5fr 1fr 6fr;
    grid-template-gap: $gutter-width;
  }

  .program-type-image {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .program-type-description {
    grid-column: 2/4;
    grid-row: 1/2;
    padding-left: 19%;
  }
}

.program-recommendations {
  @include trailer(4);

  // h3 { text-transform: uppercase; }
  .media { margin-top: 1.7em; min-width: 300px; }
  .media-item { width: 30%; }
  .media-body { font-size: .8em; line-height: 1.6; }
}


// Triangle Animation
// ----------------------------------------------------------------
@keyframes fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadein-grow {
  0%   { opacity: 0; transform: scale(.5); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes fadein-slideright {
  0%   { opacity: 0; transform: translateX(-20px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes fadein-slideleft {
  0%   { opacity: 0; transform: translateX(20px); }
  100% { opacity: 1; transform: translateX(0); }
}

.triangle-animation {
  .stage-bar {
    opacity: 0;
    animation: fadein 1s ease-in-out forwards;
  }
  .stage-text {
    opacity: 0;
    animation: fadein-slideright .75s ease-in-out forwards;
  }
}

#triangle-bar-beginning-writers             { animation-delay: 1s; }
#text-beginning-writers                     { animation-delay: 1.25s; }

#triangle-bar-growing-writers     { animation-delay: 1.5s; }
#text-growing-writers             { animation-delay: 1.75s; }

#triangle-bar-middle-school-writers     { animation-delay: 2.25s; }
#text-middle-school-writers             { animation-delay: 2.5s; }

#triangle-bar-high-school-writers { animation-delay: 3s; }
#text-high-school-writers         { animation-delay: 3.25s; }

#triangle-bar-college-prep-writers  { animation-delay: 3.75s; }
#text-college-prep-writers          { animation-delay: 4s; }


// Puzzle Animation
// ----------------------------------------------------------------
.puzzle-animation {
  #puzzle-border,
  #puzzle-left,
  #puzzle-right,
  #puzzle-center,
  .program-text {
    opacity: 0;
  }
  
  #puzzle-center { animation: fadein-grow       1s 1s ease-in-out forwards; transform-origin: 36% 21%; }
  #puzzle-left   { animation: fadein-slideright 1s 2s ease-in-out forwards; }
  #puzzle-right  { animation: fadein-slideleft  1s 3s ease-in-out forwards; }
  #puzzle-border { animation: fadein            1s 4s ease-in-out forwards; }
  .program-text  { animation: fadein            1s 4s ease-in-out forwards; }
}


// Designed-for
// ----------------------------------------------------------------
.designed-for-category {
  background: transparent url('/images/pages/getting-started/bg-designed-for-individuals.jpg') 50% 0 / 100% no-repeat;
  padding-top: 35%;
  position: relative;
  margin-top: 6em;
}
.designed-for-category-inner {
  background-image: linear-gradient(rgba(255,255,255,0) 0, rgba(255,255,255,1) 10em);
  margin-top: -10em;
  padding-top: 6.5em;
}
.designed-for-icon {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 30%;
}