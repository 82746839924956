// Summary/Details accordian elements
// ----------------------------------------------------------------
$summary-icon-color: 'rgba(42, 197, 232, 1.00)' !default;
$details-padding: 1rem;

details {
  border: 1px solid #ddd;
  border-radius: .5em;
  overflow: hidden;
  padding: 1rem;
  transition: all .25s;

  &[open] {
    background-color: #fafafa;

    > summary::before {
      content: url('data:image/svg+xml; utf8, <svg fill="#{$summary-icon-color}" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M17.857 0c.595 0 1.101.208 1.518.625.417.417.625.923.625 1.518v15.714c0 .595-.208 1.101-.625 1.518a2.066 2.066 0 0 1-1.518.625H2.143a2.066 2.066 0 0 1-1.518-.625A2.066 2.066 0 0 1 0 17.857V2.143C0 1.548.208 1.042.625.625A2.066 2.066 0 0 1 2.143 0h15.714zM16.43 11.25v-2.5a.517.517 0 0 0-.157-.38.517.517 0 0 0-.38-.156H4.108a.517.517 0 0 0-.38.157.517.517 0 0 0-.156.379v2.5c0 .149.053.275.157.38.104.104.23.156.38.156h11.785a.517.517 0 0 0 .38-.157.517.517 0 0 0 .156-.379z"/></svg>');
    }
  }

  *:last-child {
    margin-bottom: 0;
  }
}

summary {
  font-weight: bold;
  color: $link-color;
  cursor: pointer;
  display: block;
  list-style-image: none;
  display: flex;
  align-items: center;
  padding: $details-padding;
  margin: -1 * $details-padding;

  &::before {
    content: url('data:image/svg+xml; utf8, <svg fill="#{$summary-icon-color}" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M17.857 0c.595 0 1.101.208 1.518.625.417.417.625.923.625 1.518v15.714c0 .595-.208 1.101-.625 1.518a2.066 2.066 0 0 1-1.518.625H2.143a2.066 2.066 0 0 1-1.518-.625A2.066 2.066 0 0 1 0 17.857V2.143C0 1.548.208 1.042.625.625A2.066 2.066 0 0 1 2.143 0h15.714zM16.43 11.25v-2.5a.517.517 0 0 0-.157-.38.517.517 0 0 0-.38-.156h-4.106V4.107a.517.517 0 0 0-.157-.38.517.517 0 0 0-.379-.156h-2.5a.517.517 0 0 0-.38.157.517.517 0 0 0-.156.38v4.106H4.107a.517.517 0 0 0-.38.157.517.517 0 0 0-.156.379v2.5c0 .149.053.275.157.38.104.104.23.156.38.156h4.106v4.107c0 .149.052.275.157.38.104.103.23.156.379.156h2.5a.517.517 0 0 0 .38-.157.517.517 0 0 0 .156-.38v-4.106h4.107a.517.517 0 0 0 .38-.157.517.517 0 0 0 .156-.379z"/></svg>');
    margin-right: 0.5em;
    width: 20px;
    height: 20px;
    opacity: .5;
    transition: opacity .25s;
  }
  &:hover {
    text-decoration: underline;
  }
  &:hover::before {
    opacity: 1;
  }

  & + * {
    margin-top: $details-padding;
    border-top: 1px solid rgba(42, 197, 232, 1.00);
    padding-top: $details-padding;
  }
}
summary::-webkit-details-marker {
  display: none;
}

.pseudo-details-link {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .5em;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: .5em;
  text-decoration: none;
  font-weight: bold;
  color: palette_new(purple);

  &::before {
    content: url('data:image/svg+xml; utf8, <svg fill="#{$summary-icon-color}" xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path fill-rule="nonzero" d="M17.143 0H2.857A2.857 2.857 0 0 0 0 2.857v14.286A2.857 2.857 0 0 0 2.857 20h14.286A2.857 2.857 0 0 0 20 17.143V2.857A2.859 2.859 0 0 0 17.143 0Zm-3.277 11.009-5 5a1.428 1.428 0 1 1-2.02-2.02L10.839 10 6.85 6.009a1.428 1.428 0 1 1 2.02-2.02l5 5c.278.279.417.645.417 1.011 0 .366-.139.732-.42 1.009Z"/></svg>');
    width: 20px;
    height: 20px;
    opacity: .5;
    transition: opacity .25s;
  }

  &:hover {
    color: palette_new(purple);
    text-decoration: underline;
  }
  &:hover::before {
    opacity: 1;
  }
}