// SCSS port of CSS at http://htmldog.com/articles/suckerfish/dropdowns/
// Built with compass http://www.compass-style.org
// Generates the styles for suckerfish dropdowns

 
// Pass in the parameters $topLevelWidth and $lowerLevelWidth. The former controls the width of the first level,
// i.e. the visible level. The lower level is the width of the sub-levels, which get exposed on hover.

// Call this mixin with @import suckerfish(topLevelWidth, lowerLevelWidth), then style it as needed.

// To override any of these styles, place your code after the @import directive.

// This will handle up to three levels of dropdowns.

// Be sure to minify before deploying to production.


@mixin dropdown-nav($lower-level-width: 200px, $multi-level: true) {
  ul { 
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 1;
  }
  a {
    display: block;
  }
  li {
    &:hover, &.sfhover {
      .nav-dropdown {
        left: auto;
        opacity: 1;
        transform: translateY(0);

        li {
          display: block;
        }

        @if multi-level == true {
          .nav-dropdown { left: -999em; }
        }
        
      }
    }

    @if multi-level == true {
      li:hover, li.sfhover {
        .nav-dropdown { left: auto; }
      }
    }

    .nav-dropdown {
      position: absolute;
      width: $lower-level-width;
      left: -999em;
      opacity: 0;
      transform: translateY(-.25rem);
      transition: .2s opacity, .3s transform;

      @if multi-level == true {
        ul { margin: -1em 0 0 $lower-level-width; }
      }

    }
  }
}