// Comments Heading
// ----------------------------------------------------------------

.comments-heading {
  border-top: solid 1px $divider-color-alt;

  h2, h3, h4 {
    color: palette(purple);
  }
}




// Comment Forms
// ----------------------------------------------------------------

@mixin comment-form-box {
  background-color: #eaeaea;
  padding: 1em;
  border-radius: $default-border-radius;
}


// Fix spacing and froala clipping in default styles
.cc-w article.cc-f {
  @include comment-form-box;
  display: flex;
  align-items: flex-start;

  // Hide the avatar
  .cc-i-figure {
    display: none;
  }
}
.cc-w .cc-f-row {
  margin: 0;
  padding: .125em;
}
.cc-w .cc-f-col {
  width: 100%;
  padding: 0;
}

// Fix paragraph spacing within blockquote
.cc-w blockquote p {
  margin: revert;
}

// quote buttons
.cc-i-wrap .button {
  max-width: 22.5em;
}

// Hide the Quote comment button in the primary reply form
.cc-w > .cc-i[data-role="form"] *[data-action="quote-reply"] { display: none; }



// Comment Item
// ----------------------------------------------------------------

// Comment Avatar
.cc-i-image {
  font-size: .5em;
  width: auto;
  height: auto;
}
.conversation-comments .cc-w .cc-i-image {
  width: auto;
  height: auto;
  border-radius: 0;
}

// Nested comments
// ----------------------------------------------------------------
.conversation-comments .cc-w .cc-i .cc-i .cc-i-image {
  font-size: .4em;
  width: auto;
  height: auto;
}

// Updated comments
// ----------------------------------------------------------------
.cc-i.is-updated {
  background-color: palette(yellow, light);
  border-bottom: none;
}



// Comment Item - Actions
// ----------------------------------------------------------------

// Remove dot before first action
.cc-w .cc-i-footer .cc-ll-i a {
  padding-left: 0;
  padding-right: 1em;
}
.cc-w .cc-i-footer .cc-ll-i::before {
  content: '';
  padding: 0;
}
.cc-w .cc-i-footer .cc-ll-i:not(:first-of-type) a,
.cc-w .cc-i-footer .cc-ll-i:not(:first-of-type) .cc-a {
  border-left: solid 1px palette(grey, light);
  padding: 0 1em;
}

.cc-w .cc-ll-i a,
.cc-w .cc-ll-i .cc-a {
  color: $link-color;
}
.cc-w .cc-i-edit:hover {
  background-color: $alert-color;
  color: #fff;
}
.cc-w .cc-a.cc-i-delete:hover {
  background-color: $error-color;
  color: #fff;
}

.comment-edit-options {
  text-align: right;
  margin-bottom: .75em;

  a {
    color: $quiet-font-color;

    &:hover {
      text-decoration: none;
      background-color: $alert-color;
      color: #fff;
    }
  }
  
  .comment-delete:hover,
  .comment-flag:hover {
    background-color: $error-color;
  }
}



// Comment Item - Edit Form
// ----------------------------------------------------------------

.edit-comment-form {
  @include comment-form-box;
  padding-bottom: 1em;
  display: none;

  textarea {
    @include trailer(.5);
  }

  .btn {
    @include button;
    font-size: 1em;
    width: auto;
  }
}

.edit-comment-cancel {
  cursor: pointer;
  text-decoration: underline;
}

/* Comment Item - Meta Buttons */

.meta-buttons {
  margin-top: 10px;
}

.meta-buttons a {
  font-weight: bold;
  font-size: 11px;
  padding: 0 15px 0 0;
  color: #288ce4;
}

.meta-buttons .comment-votes {
  display: inline-block;
  margin-right: 15px;
}

.meta-buttons .comment-votes a {
  padding: 0 0 0 2px;
}

.meta-buttons .comment-votes .count {
  color: #a5b2b9;
  font-weight: 500;
  font-size: 13px;
  margin-right: 2px;
}

.meta-buttons .static-label {
  font-weight: bold;
  font-size: 11px;
  color: #a5b2b9;
}

/* Comment Item - Button */

.comment-input-btn {
  margin: 1em 0 0;
}

// Comment Item - Share/Options Dropdown
// ----------------------------------------------------------------

.comment-heading {

  .dropdown {
    float: right;
    margin-left: 20px;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-toggle {
    font-weight: bold;
    font-size: 11px;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
    min-width: 60px;
  }

  .dropdown-menu > li > a {
    font-weight: bold;
    font-size: 11px;
    padding: 5px 10px;
  }

  .dropdown-menu > li > span.comment-flag .glyphicon,
  .dropdown-menu > li > a .fa,
  .dropdown-menu > li > a .glyphicon {
    padding-right: 5px;
    width: 15px;
    text-align: center;
  }

  .dropdown-menu > li > span.comment-flag {
    display: block;
    white-space: nowrap;
    color: #aaa;
    font-weight: bold;
    font-size: 11px;
    padding: 5px 10px;
  }
}

@keyframes yellowfade {
    from { background-color: palette(yellow); }
    to { background-color: transparent; }
}
.highlight-fade {
  animation-name: yellowfade;
  animation-duration: 2s;
}