// Modal Windows
// ----------------------------------------------------------------
.modal-window {
  --modal-padding: 1em;

  position: relative;
  background: white;
  width: auto;
  padding: var(--modal-padding);
  max-width: 90%;
  margin: 2em auto;

  @include breakpoint($lg) {
    --modal-padding: 2em;
    max-width: 80%;
  }

  > *:first-child {
    margin-top: 0;
  }
}

.modal-window-small { max-width: 550px; }
.modal-window-med   { max-width: 750px; }


// Product Modal
// ----------------------------------------------------------------

.product-modal {
  --divider-color: palette_new(teal, light);
}
.modal-window .product-modal-images {
  background-color: palette_new(teal, x-light);
  border-bottom: 1px solid var(--divider-color);
  padding: var(--modal-padding);
  margin-inline: calc(-1*var(--modal-padding));
  margin-block: calc(-1*var(--modal-padding)) 0;
}
.product-modal-images .grid-scroller {
  margin-block-start: 1em;
}
.product-modal-images .grid-scroller-items img {
  display: block;
  max-width: 64px;
  aspect-ratio: 1;
  object-fit: cover;
  border: 1px solid palette_new(teal, light);
  border-radius: 2px;
}
.product-modal-header {
  padding-block: 1em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-block-end: 1px solid var(--divider-color);

  .product-actions {
    padding-block-start: .5em;
  }
}
.product-modal .image-gallery-items img {
  cursor: pointer;
  transition: border-color .2s;
  &:hover {
    border-color: palette_new(teal);
  }
}
.product-modal .image-gallery-featured  {
  --aspect-ratio: 5/3;
  display: block;
  aspect-ratio: var(--aspect-ratio);
  position: relative;
}
.product-modal .image-gallery-featured img {
  aspect-ratio: 5/3;
  object-fit: contain;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  background-color: palette_new(teal, x-light);
  cursor: zoom-in;
  transition: all .4s;

  &:not(:first-child) {
    opacity: .2;
  }

  &.is-zoomed {
    aspect-ratio: auto;
    max-height: none;
    position: static;
    cursor: zoom-out;
  }
}
.product-modal .image-gallery-featured img.fadeout {
  transition: opacity .3s;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}