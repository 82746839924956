// Footer
// ----------------------------------------------------------------

.footer-main {
  padding: 1.7em 0 3.5em;
  font-size: .8em;
  line-height: 2;
  border-top: 8px solid darken($footer-bg-color, 10);
  background: $footer-bg-color;
  color: #fff;
  text-align: left;

  a {
    @include unstyled-link-with-hover;
    @include link-colors(rgba(255,255,255,.65), rgba(255,255,255,1));
  }

  > .container {
    display: flex;
    flex-wrap: wrap;
    gap: 2em 0;
    padding: 0 2em;

    @include breakpoint($med) {
      padding: 0 1em;
    }
  }

  .footer-block,
  .footer-block-extra {
    margin-bottom: 1em;
    flex: 1 1 170px;

    h3 {
      font-size: 1.153846154em;
      margin: 0 0 .85em;
      color: white;
      text-transform: uppercase;
    }

    ul {
      @include unstyled-list;
      margin: 0;
    }
  }
}

#cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,.8);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: z-index(cookie-consent);
  padding: 1.5em;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.2);

  @include breakpoint($lg) {
    flex-direction: row;
  }

  &.is-hidden {
    display: none;
  }

  p {
    margin: 0;
    font-size: .9em;
  }

  p + p {
    margin-left: 1em;
  }

  a {
    color: #fff;
  }
}