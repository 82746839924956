// Colored Backgrounds
// ----------------------------------------------------------------

.bg-teal,
.stripe-teal {
  background: linear-gradient(#e2f3f5, #f7feff);
}
.bg-teal-bordered {
  border-top: 1px solid #e1f2f5;
  border-bottom: 1px solid #e1f2f5;
}

.bg-teal-fade,
.stripe-teal-fade {
  background-color: #fff;
  background-image: linear-gradient(#e2f3f5, #fff);
  background-repeat: no-repeat;
}
.bg-teal-fade-short {
  background-size: 100% 250px;
}

.bg-teal-flat,
.stripe-teal-flat {
  background-color: #e2f3f5;
}

.bg-teal-light {
  background-color: #f1fafa;
  border-block: 1px solid #d5efef;
}

.bg-teal-dark,
.stripe-teal-dark {
  color: white;
  background: linear-gradient(#2b7179, #39929e);
}

.bg-purple-light {
  background: #fbebf6;
}

.bg-blue-fade,
.stripe-blue-fade {
  color: white;
  background: linear-gradient(#4390A8, #7DC4D4);
}

.bg-blue,
.stripe-blue {
  color: white;
  background: palette_new(blue);
}

.bg-grey-xx-light,
.stripe-grey-xx-light {
  background-color: palette_new(grey, xx-light);
}


.bg-purple,
.banner-purple {
  background: linear-gradient(#550b5a, palette(purple));

  h1 {
    color: $header-bg-color;
  }
}

// Patterns for Stages
// ----------------------------------------------------------------

*[class*='bg-pattern'],
*[class*='banner-pattern'] {
  background: #00b9ba url(/images/patterns/00.svg) 50% 50% repeat;
  color: #fff;

  h1 { color: inherit; }
}
.bg-pattern-blue,
.banner-pattern-blue {
  background-color: palette_new(blue);

  .back-button-icon {
    background-color: rgba(0,0,0,.25);
    fill: #fff;
  }
  .back-button:hover .back-button-icon { background-color: rgba(0,0,0, 0.35); }
}


@each $stage, $color in $stages {
  .bg-stages-#{$stage} {
    background-color: #{$color};
  }
  .bg-pattern-#{$stage} {
    background: #{$color} url(/images/patterns/#{$stage}.svg) 50% 50% repeat;
  }
}
.bg-pattern-beginning-writers {
  color: #463900;
}

.bg-stages-beginning-writers-dark { background-color: #ae8c00; }
.bg-stages-emerging-writers-dark { background-color: #904e00; }
.bg-stages-middle-school-writers-dark { background-color: #a2332a; }
.bg-stages-high-school-writers-dark { background-color: #302744; }
.bg-stages-college-prep-writers-dark { background-color: #802961; }
.bg-stages-multiple-ages-dark { background-color: #466225; }


// Images Backgrounds
// ----------------------------------------------------------------

.bg-teal-swash {
  background: #f1fafa url(/images/backgrounds/bg-teal-swash.svg) 100% 50% / contain no-repeat;
}

@include breakpoint($lg) {
  .bg-bubbles {
    background: transparent url(/images/backgrounds/bg-bubbles.svg) 50% 50% / 100% repeat-y;
  }
}

.bg-image-fade-grey {
  --bg-image: url(/images/backgrounds/tmp-bgimage.jpg);

  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 100%;
    background: transparent var(--bg-image) 100% 50% / cover;
    z-index: 0;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image:  linear-gradient(to right, rgba(249, 249, 250, 1) 50%, rgba(249, 249, 250, 0) 100%);
  }

}