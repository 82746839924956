// Typography
// ----------------------------------------------------------------

// Base Type styles/sizes
// ----------------------------------------------------------------

html {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

// Links
// ----------------------------------------------------------------
a {
  @include link-colors($link-color, darken($link-color, 10));
  transition: all .25s;
}

// Images
// ----------------------------------------------------------------
figure {
  margin: 0;
}


// Headings
// ----------------------------------------------------------------

:is(h1, h2, h3, h4, h5, h6) {
  font-weight: 500;
  font-family: $heading-font;
  color: $heading-color;
  text-wrap: balance;
}

h1 { font-size: $h1-font-size; line-height: 1.2; }
h2 { font-size: $h2-font-size; line-height: 1.4; }
h3 { font-size: $h3-font-size; }
h4 { font-size: $h4-font-size; }
h5 { font-size: $h5-font-size; }
h6 { font-size: $h6-font-size; }

.h1 { @extend h1; }
.h2 { @extend h2; }
.h3 { @extend h3; }
.h4 { @extend h4; }
.h5 { @extend h5; }
.h6 { @extend h6; }


.heading-primary {
  font-size: $h1-font-size;
  color: $heading-color-alt;
}

.heading-secondary {
  font-size: $h2-font-size;
  color: $heading-color-alt;
  text-transform: uppercase;
  font-weight: 400;
}

.heading-tertiary {
  font-size: $h3-font-size;
  color: #aaaaaa;
  text-transform: uppercase;
  font-weight: 400;
}

.heading-alt {
  color: $heading-color-alt;
  font-weight: 400;
}

.page-title {
  @include rhythm(0,.5,0,.5);
  text-align: center;
  color: palette(purple);
  font-weight: 300;
  line-height: 1;
}

h2 + h3,
h2 + h2 {
  margin-top: -1.5em;
}

// Special Heading styles

.heading-cute {
  font-family: $heading-font-cute;
  line-height: 1;
  margin: .5em 0;
  font-weight: 400;
}
h1.heading-cute {
  font-size: 4.5em;
}
h2.heading-cute {
  font-size: 3.5em;
  color: palette_new(purple);
  @include breakpoint($med) {
    font-size: 4.5em;
  }
}
h3.heading-cute {
  font-size: 2.5em;
  line-height: 0.9;
  color: palette_new(blue);
  
  @include breakpoint($med) {
    font-size: 3.5em;
  }
}

.heading-purple,
.heading-cute.heading-purple {
  color: palette_new(purple);
}

.has-flourish,
.has-flourish-dot,
.heading-cute .kicker {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: url("/images/flourish-green-left.svg");
    padding-right: 1rem;
    position: relative;
    top: 12px;
  }
  &::after {
    content: url("/images/flourish-green-right.svg");
    padding-left: 1rem;
  }
  @include breakpoint(max-width $med-lg) {
    &::before,
    &::after {
      transform: scale(.6);
    }
  }
}
.has-flourish-dot {
  text-align: center;
}
.has-flourish-dot::before { content: url(/images/flourish-dot-left.svg); }
.has-flourish-dot::after { content: url(/images/flourish-dot-right.svg); }

// Generate the underline
.heading-squiggle-underline,
.heading-squiggle-underline-small {
  position: relative;
  padding-bottom: 30px;

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='754' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.611 13.683C9.74 13.683 15.534 0 27.834 0c8.154 0 7.526 5.438 11.422 9.403 6.648 0 14.26-7.478 24.335-7.478 5.092 0 9.57 2.045 14.522 2.594 29.113 3.223 57.958 1.25 87.134 1.25 7.638 0 15.418 1.227 23.06 1.227 4.274 0 7.324-1.228 9.489-1.228 5.171 0 129.008.007 134.184.228L655 12.256l99-1.68-4.795 7.192S488.789 14.653 478 14.653c16.783-1.198-111.6 0-111.6 0-9.888-1.863-33.379-2.397-43.159-2.397-4.274 0-133.218 1.352-135.388 1.352-7.638 0-25.558-2.347-33.196-2.347-26.515 0-46.88 6.656-72.18.314-2.266-.57-12.343-5.135-14.043-5.135C60.997 6.44 51.812 20 41.658 20 33.56 20 32.78 7.454 29.018 3.63c-7.135 0-13.405 15.668-25.72 15.668 0 0-2.888.43-3.257-2.327-.369-2.756 2.57-3.288 2.57-3.288z' fill='%23EDEDEE' fill-rule='evenodd'/%3E%3C/svg%3E");
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.heading-squiggle-underline-small::after {
  content: url("data:image/svg+xml,%3Csvg width='423' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.829 9.578C6.82 9.578 10.879 0 19.49 0c5.71 0 5.27 3.806 7.998 6.582 4.656 0 9.987-5.234 17.041-5.234 3.566 0 6.703 1.431 10.17 1.816 20.387 2.255 40.586.874 61.017.874 5.349 0 10.797.86 16.147.86 2.994 0 5.13-.86 6.646-.86 3.621 0 90.34.005 93.964.16l190.506 3.69-.907 3.337c-42.952-.501-83.851-.968-87.346-.968 11.752-.839-78.15 0-78.15 0-6.924-1.304-23.374-1.678-30.222-1.678-2.994 0-93.288.947-94.808.947-5.349 0-17.897-1.643-23.246-1.643-18.567 0-32.828 4.659-50.546.22-1.586-.4-8.643-3.595-9.833-3.595-5.208 0-11.64 9.492-18.75 9.492-5.67 0-6.217-8.782-8.852-11.46-4.996 0-9.387 10.968-18.011 10.968 0 0-2.022.301-2.28-1.628-.259-1.93 1.8-2.302 1.8-2.302z' fill='%23EDEDEE' fill-rule='evenodd'/%3E%3C/svg%3E ");
}

// Style the underline added via
// 
.squiggle-underline {
  --squiggle-color: palette_new(purple);
  width: 100%;
  height: 20px;
  display: block;
  fill: var(--squiggle-color);
}

.heading-swash {
  width: 100%;
  max-width: 660px;
  background: transparent url(/images/swash.svg) 50% 50% / contain no-repeat;
  text-align: center;
  color: #fff;
  font-family: $heading-font-cute;
  line-height: 1;
  margin: .5em auto;
  font-weight: 400;
  font-size: clamp(2rem, 12vw, 4.5rem);
  padding: 0.8rem 0;
}


// Body Text
// ----------------------------------------------------------------

body {
  color: $base-font-color;
  font-size: $base-font-size * .8;
  line-height: calc($base-line-height / $base-font-size);

  @include breakpoint($med-lg) {
    font-size: $base-font-size;
  }
}

.content > p:first-child {
  margin-top: 0;
}

@mixin content-link {
  color: inherit;
  border-bottom: 2px solid palette(teal, light);
  text-decoration: none;
  &:hover {
    border-bottom-color: palette(purple);
  }
}

.content-link {
  @include content-link();
}

@mixin content-type {
  .addthis_toolbox {
    a {
      @include unstyled-link;
    }
  }
}
.content-type {
  @include content-type;
}


%large-type {
  @include content-type;
  font-size: .92rem;
  line-height: 1.25;
  font-weight: 300;

  @include breakpoint($med-lg) {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}

%med-type {
  @include content-type;
  font-size: .82rem;

  @include breakpoint($med-lg) {
    font-size: 1rem;
  }
}

%med-small-type {
  font-size: .82rem;
}

%small-type {
  @extend %med-type;

  @include breakpoint($med-lg) {
    font-size: .76rem;
  }
}


hr {
  --color: $divider-color;

  border: none;
  background: var(--color);
  height: 1px;
  width: 100%;
  margin-top: $base-line-height - 2;
  margin-bottom: $base-line-height - 2;
  position: relative;
  top: -1px;
  clear: both;
}

hr.alt {
  height: 2px;
  background-color: palette_new(teal, light);
}

hr.fancy {
  background: none;
  height: 12px;
  &:after {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAwIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgNzAwIDEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZT5kaXZpZGVyPC90aXRsZT48cGF0aCBkPSJNMzUxLjQ5NCAwYzQuOTggMCA0LjU5OCAzLjI2MyA2Ljk3OCA1LjY0MiA0LjA2IDAgOC43MS00LjQ4NyAxNC44NjYtNC40ODcgMy4xMSAwIDUuODQ3IDEuMjI3IDguODcgMS41NTcgMTcuNzg3IDEuOTMzIDM1LjQwOC43NSA1My4yMzIuNzUgNC42NjYgMCA5LjQyLjczNiAxNC4wODcuNzM2IDIuNjEgMCA0LjQ3NC0uNzM3IDUuNzk3LS43MzcgMy4xNiAwIDE1LjQzNC0uMSAxOC41OTcuMDMzbDk5LjM4NyAyLjMxN0w3MDAgMi45MzNsLTIuOTMgNC4zMTZzLTE1Mi4zMjQuNzE4LTE1OC45MTUuNzE4YzEwLjI1Mi0uNzItNDMuMjA4LjcyLTQzLjIwOC43Mi02LjA0LTEuMTItMjAuMzktMS40NC0yNi4zNjUtMS40NC0yLjYxMiAwLTE4LjAwNy45MTctMTkuMzMyLjkxNy00LjY2NyAwLTE1LjYxMy0xLjQwOC0yMC4yOC0xLjQwOC0xNi4xOTggMC0yOC42MzggMy45OTMtNDQuMDk1LjE4OC0xLjM4NC0uMzQyLTcuNTQtMy4wOC04LjU3OC0zLjA4LTQuNTQ0IDAtMTAuMTU1IDguMTM1LTE2LjM1OCA4LjEzNS00Ljk0NyAwLTUuNDI0LTcuNTI4LTcuNzIzLTkuODIyLTQuMzU4IDAtNy44NjQgOS40LTE1LjcxMiA5LjQtNy44NSAwLTguMjU4LTcuNzE0LTEyLjgwMi03LjcxNC0xLjAzOCAwLTcuMTk0IDIuNzQtOC41NzggMy4wOC0xNS40NTcgMy44MDYtMjcuODk3LS4xODctNDQuMDk1LS4xODctNC42NjcgMC0xNS42MTMgMS40MDgtMjAuMjggMS40MDgtMS4zMjUgMC0xNi43Mi0uOTE2LTE5LjMzMi0uOTE2LTUuOTc0IDAtMjAuMzI1LjMyLTI2LjM2NSAxLjQzNyAwIDAtNTMuNDYtMS40MzgtNDMuMjA4LS43Mi02LjU5IDAtMTU4LjkxNi0uNzE4LTE1OC45MTYtLjcxOEwwIDIuOTMyIDEyNi42OTMgNS44MWw5OS4zODYtMi4zMTdjMy4xNjItLjEzMyAxNS40MzctLjAzMiAxOC41OTYtLjAzMiAxLjMyMyAwIDMuMTg2LjczOCA1Ljc5Ny43MzggNC42NjggMCA5LjQyLS43MzcgMTQuMDg3LS43MzcgMTcuODI0IDAgMzUuNDQ1IDEuMTg1IDUzLjIzLS43NDggMy4wMjUtLjMzIDUuNzYtMS41NTcgOC44NzItMS41NTcgNi4xNTUgMCA1LjczNSA3LjAxIDkuNzk3IDcuMDFDMzQwLjY0NiA3Ljc0NSAzNDQuMTk1IDAgMzUxLjQ5MyAweiIgZmlsbD0iI0MyRDlEQyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
    text-align: center;
  }
}

.content-intro {
  @extend %med-type;

  .heading-primary {
    text-align: center;
  }
}

.content-med-small {
  @extend %med-small-type;
}

.banner-intro,
.intro-text {
  font-family: $soft-font;
  font-size: 1.2em;
}

// Tables
// ----------------------------------------------------------------

$table-disabled-color: lighten(palette(grey, x-light), 5%);

table {
  width: 100%;
  border-collapse: collapse;

  &.hover {
    @include breakpoint($lg) {
      tbody tr:hover {
        td, th {
          background-color: #fffceb;
        }
      }
    }
  }

  &.simple {
    th, td {
      border: none;
    }
  }

  &.compact {
    th, td {
      padding: 0 .5em;
    }
  }

  th,td {
    padding: .5em;
    border-bottom: 1px solid #eeeeee;
    vertical-align: top;
    text-align: left;
  }

  thead {
    th {
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: normal;
      text-transform: uppercase;
      color: #888888;
      border-bottom: 2px solid #dddddd;
      vertical-align: bottom;
      white-space: nowrap;
    }
  }

  tbody {
    th { font-weight: bold; }
  }

  tr.odd td {
    background: #fffce5;
  }

  .disabled {
    background-color: $table-disabled-color;
    color: $quiet-font-color;
    border-bottom-color: palette(grey, x-light);

    a {
      color: inherit;
    }
  }
  
  caption {
    text-align: center;
  }

  .actions {
    text-align: right;
  }
}

// Responsive Tables
// ref: http://css-tricks.com/responsive-data-tables/
// ----------------------------------------------------------------
$resp-table-label-width: 30%;

@include breakpoint(max-width $lg) {
  table.responsive {
    display: block;
    font-size: .8em;

    // Force table to not be like tables anymore
    thead, tbody, th, td, tr { 
      display: block; 
    }

    // Hide table headers (but not display: none;, for accessibility)
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 3px solid #eee;
      padding-bottom: 1em;
      padding-top: 1em;
    }
    
    td { 
      // Behave  like a "row"
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;

      &[data-label] {
        padding-left: $resp-table-label-width + 3%;
      }

      &:last-child,
      &.no-border {
        border-bottom: none;
      }

      &.actions {
        padding-left: 0;
        text-align: center;
        &:before {
          content: '' !important;
        }
      }
    }
    
    td:before { 
      content: attr(data-label); // Add a label from the data-label attribute
      position: absolute; // Now like a table header
      top: 6px; // Top/left values mimic padding
      left: 6px;
      width: $resp-table-label-width; 
      white-space: nowrap;
      text-align: left;
      font-size: .9em;
      color: $quiet-font-color;
    }
  }
}


// Lists
// ----------------------------------------------------------------
li ul,
li ol {
  margin-top: 0;
  margin-bottom: .5em;
}
li ol {
  list-style: lower-alpha;
}
ul.unstyled,
li.unstyled {
  @include unstyled-list;
}



// Misc
// ----------------------------------------------------------------
address { font-style: normal; }
abbr { border: none; }

.alert { color: #900; }
.hidden { display: none; }

.extra { color: palette(purple); }

.highlighter {
  background-color: #fefeb4;
  font-style: normal;
  display: inline-block;
  padding-left: .25em;
  padding-right: .25em;
}

.text-quiet,
.text-quiet a {
  color: $quiet-font-color;
}

.text-center { text-align: center; }
.text-right  { text-align: right; }
.text-left   { text-align: left; }

.text-thin { font-weight: 300; }
.text-soft { font-family: $soft-font; }
strong.text-soft { font-family: $soft-font-bold; font-weight: 400; }

.text-invert {
  color: #fff;

  h1.heading-cute,
  h2.heading-cute,
  h3.heading-cute,
  a:not(.button) { color: inherit; }
}

.text-small   { font-size: .9em; }
.text-x-small { font-size: .8em; }
.text-large   { font-size: 1.2em; }

.truncate {
  display: inline-block;
  width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Registration Symbol
// adjust spacing with relative positioning to avoid line shift
.reg {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 0.5em;
  font-family: $base-font-family;
}

.no-border           { border: 0 !important; }
.no-margin           { margin: 0 !important; }
.no-trailer          { margin-bottom: 0 !important; }
.no-padding-trailer  { padding-bottom: 0 !important; }
.no-leader           { margin-top: 0 !important; }
.no-padding-leader   { padding-top: 0 !important; }

.has-leader          { @include leader; }
.has-trailer         { @include trailer; }
.has-leader-wide     { @include leader(2); }
.has-trailer-wide    { @include trailer(2); }
.has-trailer         { @include trailer; }
.has-leader-thin     { @include leader(.5); }
.has-trailer-thin    { @include trailer(.5); }

.has-padding-leader  { @include padding-leader; }
.has-padding-trailer { @include padding-trailer; }
.has-padding-leader-thin  { @include padding-leader(.5); }
.has-padding-trailer-thin { @include padding-trailer(.5); }

// Display based on breakpoint
// Mobile-first approach
// ----------------------------------------------------------------
.bp-lg-none {
  display: block;

  @include breakpoint($lg) {
    display: none;  
  }
}
.bp-lg-block {
  display: none;

  @include breakpoint($lg) {
    display: block;  
  }
}