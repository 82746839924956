// Nav
// ----------------------------------------------------------------

$dropdown-width: 18rem;

.logo {
  a, img {
    display: block;
  }
}

.nav-main {
  @include dropdown-nav($dropdown-width, false);
  padding: 0;

  > ul {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
  }
  > ul > li {
    margin-bottom: 0;
  }

  a, span:not(.text-quiet) {
    display: block;
    text-decoration: inherit;
    border-bottom: none;
    @include link-colors(palette_new(blue), palette(purple));
    fill: palette_new(blue);

    &:hover {
      fill: palette_new(purple);
    }
  }
  span:not(.text-quiet) {
    cursor: default;
  }

  path {
    fill: inherit;
  }

  // Main-level links
  > ul > li > a,
  > ul > li > span {
    font-family: $soft-font;
    border-radius: $default-border-radius;
    padding: .75em;
    font-size: 1.05em;
    border: 1px solid #fff;

    &.current {
      background-color: rgba(122,15,128,0.10);
    }
  }
  > ul > li > a:not(.button) {
    border-bottom: none;
    transition: none;
  }
}

// Buttons
.nav-main .button,
.mobile-nav-button .button {
  text-transform: none;
  display: flex;
  align-items: center;
  gap: .25em;
  background-color: #fff;
  border: 1px solid palette_new(blue);
  border-bottom-width: 1px !important;
  padding: .25em 1em;
  margin-top: .25em;
  fill: palette_new(blue);

  &:hover {
    color: palette_new(purple);
    fill: palette_new(purple);
    background-color: #fff;
    border-color: palette_new(purple);
  }
}
.mobile-nav-button .button {
  border: none;
}


.has-dropdown:hover > a,
.has-dropdown:hover > span {
  border-radius: 6px 6px 0 0;
  box-shadow: 5px 6px 0 rgba(0,0,0,.2);
  border: 1px solid #d9d9d9;
  border-bottom: none;
  background-color: #fcfcfc;
  transition: background-color .2s, box-shadow .2s ease-in, border .2s;
}

// Dropdown Nav
.nav-dropdown {
  background: #fcfcfc;
  padding: 5px;
  border-radius: 0 6px 6px 6px;
  box-shadow: 5px 6px 0 rgba(0,0,0,.2);
  border: 1px solid #d9d9d9;
  border-top: none;
  z-index: z-index(nav-dropdown);


  h4 {
    font-family: $soft-font-bold;
    font-size: 0.8em;
    text-transform: uppercase;
    color: palette_new(blue);
  }

  p {
    font-size: .75em;
    color: #6d7a7d;
    line-height: 1.6;
  }

  p, ul {
    font-family: $sans-font;
  }

  li {
    padding: 0;
    float: none;
    width: 100%;
    white-space: normal;
  }

  a {
    font-size: .85em;
    line-height: 1.7;
    padding: .5em 1em;
    color: palette_new(purple);
    &:hover {
      background: rgba(122,15,128,0.10);
    }
  }

  .chevron-list {
    margin-left: .85em;

    li::before {
      line-height: 1.7;
    }
    .text-quiet {
      font-size: .95em;
    }

    a {
      padding: .5em;
    }
  }
}

// Dropdowns that contain paragraphs and chevron-lists
.nav-main li .nav-dropdown.has-content {
  padding: 0;

  > * {
    padding: 1em;
  }
}

.nav-main li .nav-dropdown.nav-dropdown-double {
  width: 36rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.nav-dropdown-double-primary {
  border-right: 1px solid #cacacb;
}
.nav-dropdown-double-secondary {
  border-left: 1px solid #e7e7e8;
  background-color: #f4f4f5;

  h4,
  a,
  li::before {
    color: #8e9fa3;
  }
}

.nav-account {
  @include dropdown-nav(200px, false);

  > ul {
    float: right;
    position: relative;
    
    > li {
      white-space: nowrap;
      cursor: pointer;

      img {
        display: inline-block;
        margin-right: .25em;
        vertical-align: text-bottom;
      }
    }

    > li > a,
    > li > span {
      font-size: .8em;
      display: inline-block;
      padding-bottom: 1em; // to provide larger "hitbox"
    }
  }

  li:hover ul,
  li.sfhover ul {
    left: auto;
    right: 0;
    top: 1.4em;
  }

  a {
    @include unstyled-link;
    @include link-colors(palette(teal), palette(purple));
  }

  .nav-dropdown a {
    text-align: center;
  }
}
.user-dropdown {
  border-top: 1px solid #d9d9d9;
  border-radius: 6px;
}


// Subnav (note that subnav grid settings in pages/_textpage.scss)
// ----------------------------------------------------------------

.subnav {
  font-size: 15px;
  @include trailer(4);

  &-heading {
    font-size: 1.2em;
    @include rhythm(0,0,.5,.5);
    border-bottom: 1px solid palette(grey, light);
  }

  ul {
    @include unstyled-list;
    @include leader(-.25);
    margin-bottom: 0;
  }

  a {
    @include rhythm(0,.25,.25,0);
    @include link-colors(palette(teal), palette(purple));
    line-height: 1.5;
    display: block;
    text-decoration: none;
  }

  ul.secondary {
    margin-top: 0;
    margin-left: 1.5em;
    display: none;

    a {
      @include link-colors(palette(grey), palette(purple));
      font-size: 0.9333333333em;
      line-height: 1.5;
    }
  }

  li.active {
    > a {
      @include link-colors(palette(purple), palette(purple));
      font-weight: bold;
    }

    > ul.secondary {
      display: block;

      li {
        font-weight: normal;

        &.active > a {
          @include link-colors(palette(purple), palette(purple));
          font-weight: bold;
        }
      }
    }
  }
}


// Mobile nav (using jquery.mmenu)
// ----------------------------------------------------------------

#nav,
.nav-account {
  display: none;
  @include breakpoint($lg) {
    display: block;
  }
}

#mm-nav {
  background: #222;
}
.mobile-nav-button {

  @include breakpoint($lg) {
    display: none;
  }
}

.mobile-nav .has-icon {
  fill: rgba(255, 255, 255, 0.8);
  display: flex;
  gap: .5em;
}


// Search Form
// ----------------------------------------------------------------
#search-modal {
  
  h2 {
    @include rhythm(0,0,0,.25);
  }

  form {
    @include trailer;
  }

  input[type=search] {
    padding: .5em;
    position: relative;
    top: 1px;
    width: 60%;
  }
}
