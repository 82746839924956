// Timeline
// ----------------------------------------------------------------
.timeline {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  .week {
    flex: 0 1 60%;
    background-color: #fff;
    border: 3px solid #A0DADA;
    border-radius: .5em;
    padding: 1em 1.5em;
    position: relative;

    &:nth-child(odd)  {
      align-self: start;
    }
    &:nth-child(even) {
      align-self: end;
      &::after {
        right: auto;
        left: -250px; 
      }
    }
    &:last-child::after {
      display: none;
    }

    @include breakpoint(660px) {
      max-width: 65%;

      &::after {
        content: url(/images/timeline-arrow-1.svg);
        position: absolute;
        right: -250px;
        top: 100%;
        transform: translateY(-90%);
      }
      &:nth-child(4n-2)::after { content: url(/images/timeline-arrow-2.svg); }
      &:nth-child(4n-1)::after { content: url(/images/timeline-arrow-3.svg); }
      &:nth-child(4n)::after { content: url(/images/timeline-arrow-4.svg); }
    }

    h3 {
      color: palette(purple);
      margin: 0;

      & + p {
        margin-top: 0;
      }
    }
  }
}