// Filters
// ----------------------------------------------------------------

.filters {
  @include trailer;

  .filters-title {
    float: left;
    font-weight: bold;
    padding: .25em 1em .25em 0;
  }
  
  ul {
    @include horizontal-list;
  }

  li + li {
    margin-left: .25em;
  }
}

.filter {
  border-radius: $default-border-radius;
  font-size: 15px;
  display: block;
  color: palette(grey);
  padding: .25em 1em;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: palette(grey, light);
  }

  &.mixitup-control-active,
  &.active {
    background: palette(green);
    color: white;
  }
}