// Intro JS Tooltip
// ----------------------------------------------------------------
.introjs-tooltip {
  padding: 1rem 1.5rem;
  min-width: 290px;
}

.introjs-tooltiptext {
  font-size: 0.9rem;

  > :first-child { margin-top: 0; }
}

.introjs-tooltipbuttons {
  text-align: left;
  display: flex;
  padding-top: .5em;
}

.introjs-button {
  text-shadow: none;
  font-size: 0.7rem;
  margin-top: 0;
  padding-top: 0.75em;
  padding-bottom: 0.65em;
}

.introjs-skipbutton:not(.introjs-donebutton) {
  // margin-left: auto;
  // order: 99;
  // border: none;
  // background: none;
  // padding-left: 0;
  // padding-right: 0;
  
  // &:hover {
  //   box-shadow: none;
  // }
  position: absolute;
  bottom: 1.8em;
  right: 1.5em;
  font-size: .7em;
  cursor: pointer;
}

.introjs-donebutton {
  order: 99;
  margin-left: .5rem;
  margin-right: .5rem;
  color: #333;
}

.introjs-progress {
  height: 5px;
}

.introjs-progressbar {
  background-color:#96c6d5;
 }
 
 .introjs-tooltip-large {
   width: 30rem;
   max-width: 80vw;
 }
 .introjs-tooltip-intro {
   .introjs-tooltipbuttons {
     justify-content: center;
   }
   .introjs-progress {
     display: none;
   }
   .introjs-nextbutton {
     font-size: .8em;
     border-radius: .2em;
     padding-left: 1.5em;
     padding-right: 1.5em;
   }
 }

//  Hints
// ----------------------------------------------------------------
.introjs-hint-pulse {
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  background-color: hsla(53.6, 100%, 83.5%, 0.9);
  border: 1px solid hsla(53.5, 90.2%, 24.1%, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -.5rem;

  &::after {
    content: '?';
    font-weight: bold;
    color: #756906;
  }
}
.introjs-hint:hover > .introjs-hint-pulse {
  border: 1px solid hsla(53.5, 90.2%, 24.1%, 0.3);
  background-color: hsl(53.4, 100%, 67.8%);
  color: #222;
  box-shadow: 0 3px 5px rgba(0,0,0,0.3);
  top: -.6rem;
}
// hide the "OK" or "Got it" button so that hint's can't be dismissed
.introjs-hintReference .introjs-button {
  display: none;
}