// Date Square

.date-square {
  --day-width: 3.5em;
  --bgcolor: #7eb242;

  width: calc(var(--day-width)*1.375);
  height: var(--day-width);
  background-color: var(--bgcolor);
  box-shadow: rgba(0,0,0,.2) 0 1px 1px;
  color: #fff;
  font-family: $soft-font-bold;
  font-weight: bold;
  display: grid;
  grid-template-columns: calc(.375*var(--day-width)) var(--day-width);
  border-radius: 0.3em;
  overflow: hidden;
  font-size: calc(.25*var(--day-width));
}

.date-square .month {
  text-transform: uppercase;
  transform: rotate(-90deg) translateX(calc(-1*var(--day-width)));
  display: block;
  font-size: 1em;
  letter-spacing: .05em;
  line-height: calc(.375*var(--day-width));
  transform-origin: 0% 0;
  width: var(--day-width);
  height: calc(.375*var(--day-width));
  text-align: center;
}


.date-square .day {
  background: #f6f6f6;
  color: #5e5e5e;
  font-weight: normal;
  font-size: calc(.55*var(--day-width));
  display: block;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date-square-dash {
  --day-width: 2.4em;
  font-family: $soft-font-bold;
  font-weight: bold;
  font-size: calc(.55*var(--day-width));
  line-height: var(--day-width);
  text-indent: -100em;
  width: 0.75em;
  height: 0.25em;
  border-bottom: 0.2em dotted #c53b95;
}

// Events
.event {
  .event-name {
    display: block;
    line-height: 1.4;
    margin: 0 0 .15em;
  }
  .event-details {
    display: flex;
    gap: 2em;

    > * {
      display: flex;
      align-items: center;
      gap: .35em;
      font-size: .9em;
      color: #88a3aa;
    }
  }
}

.event-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.event-dates {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
}

.event-med {
  .event-name {
    margin: 0 0 .6em;
  }
}