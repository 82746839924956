.unstyled-link {
  @include unstyled-link;
}
.unstyled-link-with-hover {
  @include unstyled-link-with-hover;
}

// Remove underline
.plain-link {
  text-decoration: none;
}
.plain-link-with-hover {
  @extend .plain-link;
  &:hover { text-decoration: underline; }
}

a.learn-more {
  transition: all .25s;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.333 0a.244.244 0 00-.15.058L.725 1.516a.217.217 0 000 .301L3.907 5 .725 8.183a.217.217 0 000 .3l1.458 1.46c.08.08.22.08.3 0L7.276 5.15a.217.217 0 000-.3L2.484.058A.244.244 0 002.334 0z' fill='%236d246b' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 18px;
  border: none !important;

  &:hover {
    margin-left: 9px;
  }
}

.view-more-link {
  text-decoration: none;
  transition: all .2s;
  color: palette_new(teal);
  fill: palette_new(teal);
  display: flex;
  align-items: center;
  gap: 0.25em;
  
  svg {
    transition: all .2s;
    font-size: .8em;
  }

  &:hover svg {
    transform: translateX(.25em);
  }
}

.block-link {
  display: block;
  text-decoration: none;
}

// Anchor links generated by Craft anchor plugin (https://github.com/pixelandtonic/Anchors)
// hide these unless hovered over
h1, h2, h3, h4, h5, h6 {
  a.anchor {
    opacity: 0;
    display: none;
    border: none !important;
    color: #ccc !important;
  }
  &:hover a.anchor {
    display: inline;
    opacity: 100;
  }
}