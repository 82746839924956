// Online Class Listing
// ----------------------------------------------------------------

.learn-more-panel {
  margin: 0;
  z-index: 1;

  @include breakpoint($lg) {
    margin: .5em 0 -.5em;
  }

  strong {
    color: palette_new(purple);
  }
}

.register-heading h3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > *:last-child {
    margin-left: auto;
    // margin-top: 1em;
  }
}

.stripe-online-class-notifications {
  a {
    position: relative;
    left: -3em;
    color: palette_new(teal);

    &:hover {
      color: darken(palette_new(teal), 10);
      img {
        transform: scale(1.05);
      }
    }
  }
  img {
    position: relative;
    top: -.75em;
    margin-bottom: -.75em;
    transition: all .2s;
  }
}


// Featured Box (Core Classes)
// ----------------------------------------------------------------
.stripe-featured-online-classes {
  .box-blue.grid {
    grid-template-columns: 1fr;

    @include breakpoint($lg) {
      grid-template-columns: 1fr 2fr;
    }
  }
  .class-card-body {
    padding-bottom: 1em;
  }
  .class-card-footer {
    display: none;
  }
}


// Online Class Grid
// Allow 2-across on smaller (phone) screens, but ensure the cards are bigger at desktop
.online-classes-grid {
  --grid-item-width: 165px;
  grid-template-columns: repeat(auto-fill,minmax(var(--grid-item-width),1fr)); // fill, don't fit

  @include breakpoint($med-lg) {
    --grid-item-width: 235px;
  }

  > .heading-squiggle-underline {
    grid-column-start: 1;
    grid-column-end: -1;
    padding-top: 1em;
  }
}

.online-class-grid-controls {
  display: flex;
  align-items: flex-end;
  min-height: 11em; // prevent jumping up/down during button transitions, make space for avatars
  overflow-x: scroll;
  overflow-y: visible;
  padding: .5em;

  @include breakpoint($xl) {
    justify-content: center;
  }

  > * {
    margin: .25em;
  }
}

// Online Class Card
// ----------------------------------------------------------------
.class-card {
  background: #fff;
  border: 1px solid #dfdfe0;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all .2s;

  &:hover {
    transform: translateY(-5px);
  }

  a {
    display: block;
    text-decoration: none;
    position: relative;
    z-index: 1; // ensure non-breakout links are above breakout
    color: inherit;
  }
  .class-card-link {
    position: static;

    &::before { // breakout link, ref. https://css-tricks.com/breakout-buttons/
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  .badge {
    font-size: 0.6em;
    margin: 0;
  }
}
.class-card-title {
  text-transform: uppercase;
  font-family: $soft-font-bold;
  font-size: 1em;
  line-height: 1.2;
  margin: 0.75em 0 .25em;
  color: palette_new(purple);
}
.class-card-body {
  padding: 0 1em;
}
.class-card-footer {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: .25em;
  padding: .5em 1em 1em;
}

// Online Class Detail
// ----------------------------------------------------------------

// Banner
// ----------------------------------------------------------------
.class-banner {
  // TODO: add text-shadow
  // @include text-shadow;
  @include rhythm(0,1,1,0);
  box-shadow: 0 8px 0 rgba(0,0,0,.12);
  background: #371909 url('/images/pages/online-class/tmp-banner-bg.jpg') 50% 50% no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  z-index: 1;

  .container {
    position: relative;
  }
  
  &-content {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($med-lg) {
      flex-wrap: nowrap;
    }

    > * {
      @include breakpoint($med) {
        padding-left: .5rem;
        padding-right: .5rem;
      }
      @include breakpoint($med) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  &-thumbnail {
    flex: 1 1 25%;
    min-width: 160px;
    max-width: 320px;

    img {
      box-shadow: $default-box-shadow;
      border: 1px solid rgba(255, 255, 255, .25);
      display: block;
    }
  }

  &-overview {
    flex: 1 1 66%;
  }

  &-go-back {
    display: none;

    @include breakpoint($med-lg) {
      display: block;
      padding-top: 4.5rem;
    }
  }
  
  &-go-back-button {
    @include hide-text;
    border-radius: $default-border-radius;
    display: block;
    position: relative;
    height: 3em;
    width: 3em;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .2);

    &:hover {
      background-color: rgba(255, 255, 255, .3);
    }

    &:after {
      content: url('/images/arrow-go-back.svg');
      text-indent: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -15px 0 0 -13px;
    }
  }

  .class-title,
  .class-teachers,
  .class-signup-notes {
    color: inherit;
    font-weight: 100;
  }

  .class-title {
    margin: .25em 0;
  }

  .class-teachers {
    margin: 0 0 1.5em;
    font-size: 18px;

    a {
      @include content-link;
    }
  }
}

.class-banner-simple {
  .class-banner-content {
    align-items: center;
  }
  .class-banner-thumbnail {
    flex-basis: 10%;
  }
  .class-banner-go-back {
    padding-top: 0;
  }
}

// Schedule
// ----------------------------------------------------------------

.class-schedule-table {
  font-size: .9em;

  td {
    vertical-align: middle;
  }
}
.class-schedule-table .class-type {
  justify-content: start;
  text-decoration: none;

  strong {
    text-decoration: underline;
  }
  img {
    max-width: 7em;
  }
}


// Semester of Classes
.semester-box .semester-title {
  text-align: center;
  margin: 0 0 .25em;
  font-family: $soft-font-bold;
  color: palette_new(purple);
}
.semester-box h4 {
  font-size: .9em;
  line-height: 1.5;
}
.semester-box h4 .text-quiet {
  display: inline-block;
  padding-left: 2em;
  padding-bottom: 0.5em;
}
.semester-box .coach {
  display: flex;
  align-items: start;
  gap: 1em;
}
.semester-box .coach-image {
  max-width: 5em;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover
}
.semester-box .coach-bio h4 {
  font-size: 1em;
  font-weight: bold;
}
.semester-box .coach-bio h4 + * {
  margin-top: .25em;
}
.class-image-stack {
  position: relative;
  display: block;
  margin-bottom: 4em;
  margin-top: -2em;

  img {
    border: 1px solid #fff;
    border-radius: 2px;
    max-width: 70%;

    &:not(:nth-child(1)) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-child(2) { top: 1.5em; left: 3em; }
    &:nth-child(3) { top: 3em; left: 6em; }
    &:nth-child(4) { top: 4.5em; left: 9em; }
  }
}

// New Online Classes Page
// ================================================================

.online-class {
  .kicker {
    text-align: center;
    font-family: $soft-font-bold;
    text-transform: uppercase;
    color: palette(grey, mid-light);
    margin-bottom: 0;

    & + .heading-cute {
      margin-top: 0;
    }
  }
  .illustration-list {
    color: palette(purple);
  }

  details {
    background-color: #fff;
  }
  summary {
    color: palette(purple);
  }
  details[open] summary {
    color: palette(green);

    & + * {
      border-top-color: palette(green);
    }
  }
}

// Banner
// ----------------------------------------------------------------
.online-class-banner {
  --image-width: 316px;

  padding-top: 1em;
  padding-bottom: 1em;
  color: #fff;

  .back-button {
    align-self: center;
  }

  .class-image {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .class-banner-overview {
    grid-column: 3/4;
    grid-row: 1/2;
    text-align: center;
  }

  .class-title {
    font-family: $heading-font-cute;
    font-size: 4.25em;
    line-height: 1;
    margin: .125em 0;

    .kicker {
      font-family: $soft-font;
      font-size: 0.3em;
      display: block;
      color: #FACFF9;
      text-transform: none;
      text-align: inherit;
    }
  }

  .class-banner-actions {
    grid-column: 2/4;
    grid-row: 2/3;
    margin: 1em 0;
    background-color: rgba(0,0,0,.2);
    border-radius: .25em;
    padding: .5em;
    display: flex;
    flex-direction: column;
    gap: .5em;

    .button {
      border: none;
      color: palette(purple);
      font-size: 1.25em;
      box-shadow: 5px 5px 0 0 rgba(0,0,0,0.30);
    }

    ul {
      margin: 0;
      padding: 0 1em;
      list-style: none;
      flex: 1;
      display: flex;
      gap: 1em;
      justify-content: center;
    }

    li {
      display: flex;
      align-items: start;
    }

    li::before {
      content: '»';
      width: 1em;
    }

    li a {
      color: inherit;
      text-decoration: none;
    }
  }

  @include breakpoint(660px) {
    > .container {
      display: grid;
      gap: 1em;
      grid-template-columns: $back-button-width 35% auto;
    }
    .class-banner-overview {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .class-title {
      font-size: 5.75em;
      line-height: 0.8;
    }
    .class-tags {
      justify-content: start;
    }
  }

  @include breakpoint(960px) {
    > .container {
      display: grid;
      gap: 1em;
      grid-template-columns: $back-button-width var(--image-width) auto;
    }
    .class-banner-actions {
      margin: 0;
      padding: 0;
      flex-direction: row;
      gap: 1em;

      .button {
        flex: 0 0 var(--image-width);
      }
      ul {
        padding: 0 1em 0 0;
        justify-content: space-evenly;
      }
      li {
        align-items: center;
      }
    }
  }
}

// Registration Popup
// ----------------------------------------------------------------
.register-modal {

  .class-title {
    color: palette_new(purple);
    font-family: $heading-font-cute;
    font-size: 4.25em;
    line-height: 1;
    margin: .125em 0;
    text-align: center;

    .kicker {
      font-family: $soft-font-bold;
      font-size: 0.3em;
      display: block;
      color: #D977D6;
      text-transform: none;
    }
  }

  .registration-details-list {
    padding: 1px 1em;
    margin-inline: -1em;

    @include breakpoint(900px) {
      margin-inline: -2em;
    }
  }

}

.registration-table {
  font-size: .9em;

  .date-square {
    background-color: palette_new(teal);
  }
  strong {
    font-size: 1.1em;
  }
  td {
    vertical-align: middle !important;
  }
  tr:last-child td {
    border-bottom: none;
  }
}
  
// Intro
// ----------------------------------------------------------------
.bg-image-fade-grey {
  &::before {
    display: none;
  }

  @include breakpoint(660px) {
    .intro {
      padding-left: calc($back-button-width + 1em);
      width: 60%;
    }
    &::before {
      display: block;
    }
  }
}


// Registration Details
// ----------------------------------------------------------------
.registration-details {
  .box {
    background-color: #fff;
    border: 3px solid #a0dada;
    border-radius: .5em;
    color: $base-font-color;

    .heading-cute {
      color: palette(purple);
      margin-bottom: 0;
    }
  }
}

.registration-details-list {
  padding: 1px;
  background-color: #f1fafa;
  margin-inline: -1.25rem;
  
  dl {
    margin: 1em 0;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    gap: 0 1em;
    font-size: .85em;
  }
  dl > div {
    display: flex;
    gap: 1em;
    justify-content: center;
  }
  dt {
    font-weight: bold;
    text-align: right;
    flex: 1 1 35%;
  }
  dd {
    flex: 1 1 65%;
    margin: 0;
  }
  dd > :first-child { margin-top: 0; }
  dd > :last-child { margin-bottom: 0; }

  .button {
    box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
  }
}


// Testimonials
// ----------------------------------------------------------------
.testimonial-boxes {
  display: grid;
  gap: 1em;

  @include breakpoint($med-lg) {
    grid-template-columns: 1fr 1fr;
  }
  
  @include breakpoint($lg) {
    grid-template-columns: 1fr 5fr .5fr 4fr 1fr;
    grid-template-rows: 1fr 4fr 1fr 4fr 1fr;
  
    > :nth-child(4n-3) { grid-column: 1/4; grid-row: 1/3; }
    > :nth-child(4n-2) { grid-column: 4/5; grid-row: 2/4; }
    > :nth-child(4n-1) { grid-column: 2/3; grid-row: 3/5; }
    > :nth-child(4n)   { grid-column: 3/6; grid-row: 4/6; }
  }
}


// How Our Classes Work Page
// ================================================================

.grid.how-features-icons {
  --grid-item-width: 100px;
  line-height: 1.2;

  > * {
    transform: scale(.9);
  }

  @include breakpoint(475px) { --grid-item-width: 130px; }
  @include breakpoint(600px) { --grid-item-width: 180px; }
  @include breakpoint(800px) { --grid-item-width: 100px; }

  img {
    display: block;
    margin-inline: auto;
  }
}

#how-our-classroom {
  overflow: clip;

  .classroom-screenshot {
    border: 2px solid palette_new(purple);
    border-radius: 1em;
    box-shadow: .5em .5em 0 rgba(0,0,0,.125);

    @include breakpoint($lg) {
      max-width: 125%;
      width: 125%;
      position: relative;
      right: -2em;  
    }    
  }
  .classroom-screenshot-arrow {
    display: none;

    @include breakpoint($lg) {
      display: block;     
      position: relative;
      right: -2.5em; 
      top: 1em;
    }
  }
}

#how-sample-class {
  .box {
    background-color: #fff;
    border: 3px solid #a0dada;
    border-radius: .5em;
    color: $base-font-color;

    .heading-cute {
      color: palette(purple);
      margin-block: .125em 0;
    }
  }

  .how-steps {
    --gap: 3em;
    list-style: none;
    counter-reset: steps;
    font-size: 0.85em;
    line-height: 1.6;
    padding-inline: 3%;
    
    li::before {
      content: counter(steps);
      counter-increment: steps;
      display: block;
      text-align: center;
      color: #fff;
      font-family: $heading-font-cute;
      background: transparent url(/images/rough-circle-green.svg) 50% 50% / contain no-repeat;
      padding: 0.25em 0 0.4em;
      font-size: 3em;
      line-height: 0.7;
    }

    code {
      font-size: 0.9em;
      font-weight: bold;
    }
  }

  .persona {
    display: none;

    @include breakpoint($lg) {
      display: block;
      margin-block-end: -8em;
      position: relative;
      right: -2em;
    }
  }
}

#how-community {
  @include breakpoint($lg) {
    // First and fourth item is double-width
    .content-features-grid > *:nth-child(1) {
      grid-column: 1/3;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4em;
    }
    // The item should be reversed horizontally (image first, then content)
    .content-features-grid > *:nth-child(1) > img {
      order: -1;
    }
  }
}