// Image left, content right
// 

$media-gutter: 1rem !default;

.media {
  display: flex;
  align-items: flex-start;
}

.media-item {
  margin-right: $media-gutter;

  img {
    display: block;
  }
}

.media-body {
  flex: 1;
}

.media-reversed {
  flex-direction: row-reverse;
}



// Center items
.media-valign,
.media-centered {
  align-items: center;
}

// Allow object to wrap if too wide
.media-wrap {
  flex-wrap: wrap;
  justify-content: center;

  > .media-item {
    margin-bottom: $media-gutter;
  }
  > .media-body {
    min-width: 300px;
  }
}


// Stacked media object
.media-stacked {
  text-align: center;
  flex-direction: column;
  align-items: center;

  > .media-item {
    @include trailer;
    float: none;
    margin-right: 0;

    img {
      margin: 0 auto
    }
  }
}

// Media Feature
// shows media-item to the left at smaller sizes, but on top at larger
// this is the reverse of the standard media object
.media-feature {
  text-align: left;

  > .media-item {
    flex: 1 0 20%;
    margin-right: $media-gutter;
  }
  > .media-body {
    flex: 1 0 75%;
    
    > *:first-child {
      margin-top: 0;
    }
  }

  @include breakpoint($lg) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    &.text-left {
      align-items: flex-start;
    }

    > .media-item {
      margin-right: 0;
    }

    > .media-item,
    > .media-body {
      flex: 0 0 auto;
      max-width: 100%; // for IE 11
    }
  }
}