// Forms
// ----------------------------------------------------------------

$left-label-width       : 20% !default;
$left-label-margin      : 2% !default;
$left-label-input-width : 75% !default;

$input-v-padding: .35 * $base-line-height;
$input-h-padding: .5em;

$error-color: #c00 !default;


// @mixin form-defaults() {
//   // Defaults from http://html5boilerplate.com
  
//   textarea { overflow: auto; } /* www.sitepoint.com/blogs/2010/08/20/ie-remove-textarea-scrollbars/ */
   
//   .ie6 legend, .ie7 legend { margin-left: -7px; }
   
//   // Align checkboxes, radios, text inputs with their label by: Thierry Koblentz tjkdesign.com/ez-css/css/base.css
//   input[type="radio"] { vertical-align: baseline; }
//   input[type="checkbox"] { vertical-align: baseline; }
//   .ie7 input[type="checkbox"] { vertical-align: baseline; }
//   .ie6 input { vertical-align: text-bottom; }
   
//   // Hand cursor on clickable input elements
//   label, input[type="button"], input[type="submit"], input[type="image"], button { cursor: pointer; }
   
//   // Webkit browsers add a 2px margin outside the chrome of form elements
//   button, input, select, textarea { margin: 0; }
  
//   // make buttons play nice in IE:   
//   // www.viget.com/inspire/styling-the-button-element-in-internet-explorer/
//   button {  width: auto; overflow: visible; }
   
//   // Colors for form validity
//   //input:valid, textarea:valid   {  }
//   //input:invalid, textarea:invalid { border-radius: 1px; @include single-box-shadow(#f00, 0, 0, 5px); }
//   //.no-boxshadow input:invalid, .no-boxshadow textarea:invalid { background-color: #f0dddd; }
// }


// FORM FIELD DEFAULTS
// ========================================
@mixin no-label-padding {
  padding-left: $left-label-width + $left-label-margin;
}

fieldset {
  @include trailer;
  border: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;

  > p,
  > .field-group > p {
    margin: 0;
  }
  > p + p,
  > p + .field-group,
  > .field-group + p {
    @include leader;
  }
}


fieldset.bordered + fieldset.bordered {
  border-top: 2px solid palette_new(teal, light);
  @include padding-leader;
}

form {
  > fieldset > ol, > ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    
    > li {
      @include trailer;
      margin-left: 0;
      padding: 0;
    }
  }
}

// .inputgroup {
//     margin-left: $left-label-width;
//     padding-top: .5em;
//   }
//   ul.inputgroup {
//     clear: none;
//     list-style: none;
//     margin-bottom: 0;
//     li {
//       margin-bottom: 0;
//       label {
//         width: auto;
//         font-weight: normal;
//       }
//     }
//   }

.field-group {
  @include pie-clearfix;
  clear: both;
}

.field-inline-block {
  @include breakpoint($lg) {
    width: auto;
    display: inline-block;
    padding-right: 2em;
    vertical-align: top;
  }
}
        
.field-instructions {
  font-size: 0.91em;
  color: #94b4b8;
  font-style: italic;
}


legend {
  @include trailer(.5);
  display: block;
  font-family: $heading-font;
  color: palette_new(purple);
  font-size: 1.5em;
  white-space: normal;
}

label {
  display: block;
  font-weight: bold;

  // Froala override
  .fr-popup & {
    display: inline;
    font-weight: normal;
  }
}

%text_input {
  font-family: $sans-font;
  font-size: $base-font-size;
  line-height: $base-line-height;
  border: 1px solid #c6c7c0;
  border-radius: 3px;
  margin: 0;
  padding: $input-v-padding $input-h-padding;
  width: 100%;
  max-width: 100%;
  
  &.tiny  { width: 7em; }
  &.short { width: 18em; }
  &.medium { width: 30em; }
  // &.large { width: $left-label-input-width * 1.25; }
  // &.bigtext { font-size: 18px; line-height: $base-line-height * 2; }    
  // &.default,
  // &.placeholder { color: #999999; }
  
  &:active:not([disabled]), &:focus:not([disabled]), &:hover:not([disabled]) {
    border-color: #555555;
  }

  &.error {
    border-color: $error-color;
  }

  &[readonly] {
    color: #999;
  }
  &[disabled] {
    background-color: #f1f1f1;
  }
}

input::placeholder {
  color: #6d6d6d;
}

input.text,
input[type=text],
input[type=email],
input[type=url],
input[type=tel],
input[type=password],
input[type=date],
input[type=search],
textarea {
  @extend %text_input;
}

textarea {
  height: 8em;
}

.form-buttons {
  @include rhythm(2,2,0,1);
  border-top: 2px solid $divider-color;

  &.no-border {
    @include padding-leader(1);
    border-top: none;
  }

  button,
  input[type=submit] {
    @include trailer;
  }

  &-alt {
    float: right;
  }
}

.or {
  display: inline-block;
  padding: 0 .75em;
}

.checkboxes,
.radios {
  list-style: none;
  padding-left: .25em;

  li {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
  }
  input[type=checkbox],
  input[type=radio] {
    margin-right: .5em;
    display: inline-block;
  }
  
  label {
    width: auto;
    display: inline-block;
    font-weight: normal;
    padding-right: 2em; // for when displayed inline
  }

  // hanging radio buttons in paragraphs
  p {
    padding-left: 1.6em;

    input[type=checkbox],
    input[type=radio] {
      margin-left: -1.6em;
    }
  }

  p + p {
    @include leader(.5);
  }
}


// Left Labels
// ----------------------------------------------------------------
$left-label-width-flex: 10em;

.left-labels {
  > * {
    display: flex;
    align-items: center;
  }

  label,
  .label {
    order: 1;
    width: $left-label-width-flex;

    & + * {
      order: 2;
      flex: 1 1 auto;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    order: 1;
    flex: none;
    width: auto;

    * ~ label,
    * ~ .label {
      width: auto;
      padding-left: 0.4em;
    }
  }
}


// Left Legends
// ----------------------------------------------------------------
.left-legend {
  @include breakpoint($med) {
    padding-left: $left-label-width + $left-label-margin;
    position: relative;

    > legend {
      width: $left-label-width;
      position: absolute;
      left: 0;
      font-size: 1.25em;
    }
  }
}


// Errors and Alerts
// ----------------------------------------------------------------
.error {
  color: $error-color;
}
.suggestion {
  font-size: .9em;
  font-style: italic;
  color: #444;
  margin: .5em 0;
}

// input.checkbox, input[type=checkbox] {
//   margin-right: 0.5em;
//   display: inline-block;
// }
// label.checkbox {
//   float: none;
//   width: auto;
//   display: inline-block;
// }

/* Top Labels
---------------------------------------- */
// .top_labels,
// .stacked {
//   li {
//     margin-bottom: 1.2em;
//     li {
//       margin-bottom: 0;
//     }
//   }
//   label {
//     display: block;
//     float: none;
//     width: auto;
//     margin: 0;
//   }    
//   .checkboxes label,
//   .radios label,
//   .choices-group label {
//     display: inline;
//   }
//   input.text,
//   input[type=text],
//   input[type=email],
//   input[type=url],
//   input[type=tel] {
//     margin-left: 0;
//   }
//   .submit, &.submit {
//     padding-left: 0;
//   }
// }



// Search field
// ----------------------------------------------------------------
@mixin unstyled-search-input {
  // Color of placeholder text
  &::-webkit-input-placeholder { color: $quiet-font-color; }
  &:-moz-placeholder { color: $quiet-font-color; }

  // Disable webkit appearance
  -webkit-appearance: textfield;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  
  // Disable focus rectangle
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}


// Filters
// ----------------------------------------------------------------
// .filters {
//   @include pie-clearfix;
//   @include trailer;

//   @include breakpoint($lg) {
//     &-selects {
//       @include span(6);
//     }

//     &-search {
//       @include span(last 6);
//       text-align: right;
//     }
//   }

//   select {
//     font-size: 1.1em;
//   }
// }

// BLH Search Box
// ----------------------------------------------------------------
.blh-search {
  --button-width: 2.35em;

  display: flex;
  align-items: center;

  :is(input[type=search], input[type=image]) {
    padding: .6em;
    font-size: .9em;
    line-height: 1;
  }

  input[type=search] {
    min-width: 36ch;
    position: relative;
    width: auto;
    border-color: palette(grey, mid-light);
    padding-inline-end: calc(var(--button-width) * 1.1);

    &::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
      color: palette(grey, mid-light);
      text-transform: uppercase;
    }
    &:hover {
      border-color: palette(grey, mid-light);
    }
  }
  input[type=image] {
    width: var(--button-width);
    margin-inline-start: calc(var(--button-width) * -1);
    z-index: 1;
  }
}