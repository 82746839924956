// Boxes
// ----------------------------------------------------------------

.box {
  --box-padding: 1.25rem;
  --box-border-radius: 3px;

  padding: var(--box-padding);
  border-radius: var(--box-border-radius);
}

.box-bordered {
  border: 1px solid palette(grey, x-light);
}

.box-bordered-thick {
  --box-padding: 2em;;
  --box-border-radius: 1em;
  
  border: 4px solid #a0dada;
  background-color: #fff;

  > :first-child { margin-block-start: 0; }
  > :last-child  { margin-block-end: 0; }
}

.box.box-blue {
  background-color: palette_new(blue, x-light);
}