// Badges and Labels
// ----------------------------------------------------------------

$badge-bg: #f7ef7e !default;
$badge-txt: #777007 !default;

@mixin badge($bg: $badge-bg, $txt: $badge-txt) {
  font-size: .7rem;
  line-height: 1.5;
  letter-spacing: .05em;
  border-radius: 3px;
  display: inline-block;
  padding: .25em .75em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: $txt;
  background-color: $bg;
}

.badge {
  @include badge;
}

.teacher-badge {
  @include badge(palette(green, bright), #fff);
}

.badge-small {
  font-size: .6rem;
}

.badge-label {
  font-size: .7em;
  font-weight: 600;
  color: palette(orange, light);
  text-transform: uppercase;
  text-decoration: none;
  padding-left: .75em;
}

// Online Class badges
.core-series-badge,
.badge-green {
  color: #fff;
  background-color: palette_new(green);
}
.family-writing-badge,
.badge-teal {
  color: #fff;
  background-color: palette_new(teal);
}
.middle-school-writing-badge {
  color: #fff;
  background-color: palette_new(levels, 3);
}
.high-school-writing-badge {
  color: #fff;
  background-color: palette_new(levels, 4);
}
.homeschool-and-personal-development-badge {
  color: #fff;
  background-color: palette_new(levels, 3);
}

.julie-badge {
  background-color: #c63a95;
}