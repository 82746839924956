// Colors
// ----------------------------------------------------------------
$palettes: (
  purple: (
    x-light: hsla(296.8, 79%, 28%, .08),
    base: #7a0f80
  ),
  teal: (
    base: #4390a8,
    light: #2ac5e8,
    dark: #2a6d76,
    x-light: #eef6f7,
    xx-light: #f5fbfc
  ),
  green: (
    base: #89aa3b,
    mid-dark: #779630,
    dark: #6a8627,
    light: #ebffbd,
    bright: #7fb118
  ),
  aquamarine: (
    base: #4eab97
  ),
  grey: (
    x-light: #e5e5e5,
    light: #dbe3e5,
    mid-light: #a6babf,
    base: #808080,
    mid-dark: #555,
    dark: #303030,
    x-dark: #222222
  ),
  orange: (
    base: #e2642a,
    light: #f79937
  ),
  blue: (
    base: #3ea8c2,
    light: #70c8e3
  ),
  pink: (
    base: #fb7ec5
  ),
  red: (
    base: #d52120
  ),
  yellow: (
    base: #ffff60,
    light: #FFFFD7
  )
);

$palettes_new: (
  purple: (
    base: #6d246b,
    dark: #440033
  ),
  green: (
    base: #7eb242
  ),
  blue: (
    base: #00b9ba,
    x-light: #e5f8f8
  ),
  teal: (
    x-light: #f1fafa,
    light:  #c3ecec,
    base: #00b9ba,
    dark: #008182
  ),
  orange: (
    base: #f7941e
  ),
  grey: (
    xx-light: #f6f6f6,
    x-light: #ededee,
    light: #dbe3e5,
    mid-light: #97aeb4,
    base: #808080,
    mid-dark: #626262,
    dark: #303030,
    x-dark: #222222
  ),
  levels: (
    1: #ffcf01,
    2: #f7941e,
    3: #f16458,
    4: #4b3f64,
    5: #c63a95,
    6: #7eb242
  )
);

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}
@function palette_new($palette, $tone: 'base') {
  @return map-get(map-get($palettes_new, $palette), $tone);
}

$stages: (
  'beginning-writers'     : #ffcf01,
  'emerging-writers'      : #f7941e,
  'middle-school-writers' : #f16458,
  'high-school-writers'   : #4b3f64,
  'college-prep-writers'  : #c63a95,
  'multiple-ages'         : #7eb242
);

$blh-palette: (
  master-class            : palette_new(blue),
  one-thing               : palette_new(green),
  community-conversations : palette_new(levels, 5),
  your-journey            : palette_new(purple),
  calendar                : palette(orange, light),
  coaching-notebook       : #68a6b9,
  webinars                : palette(orange, light),
  self-care-spa           : palette_new(purple),
  location                : #b3e5eb,
  product-trainings       : palette_new(purple),
);


$link-color         : palette_new(teal);

$header-bg-color    : white;
$footer-bg-color    : palette_new(purple);

$divider-color      : #dde4eb;
$divider-color-alt  : #96c6d5;

$base-font-color    : palette_new(grey, mid-dark);
$quiet-font-color   : palette_new(grey, mid-light);
$heading-color      : palette_new(purple);
$heading-color-alt  : palette(purple);

$hover-color        : palette(teal, x-light);

$alert-color        : palette(orange, light);
$error-color        : palette(red);


// BW Category Colors
// ----------------------------------------------------------------

$the-great-conversation-color   : palette(pink);
$transition-to-ownership-color  : palette(orange);
$faltering-ownership-color      : palette(purple);
$partnership-writing-color      : #4390a8;
$jot-it-down-color              : palette(green);
$original-thought-color         : #49ac97;
$mechanics-and-literature-color : palette(teal);
$writing-projects-color         : #96c6d5;



// Fonts
// ----------------------------------------------------------------

// Internal Fonts
@font-face {
  font-family: 'chin_up_buttercup_cutecapsRg';
  src: url('/fonts/chin_up_buttercup_cutecaps-webfont.woff2') format('woff2'),
       url('/fonts/chin_up_buttercup_cutecaps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face{
  font-family:"FilsonSoftMedium";
  src:url("/fonts/5205760/6d6a8331-b28c-4421-89df-2b3d63d5dfd6.woff2") format("woff2"),url("/fonts/5205760/70f59124-bb45-41a8-946b-425b647ccfb0.woff") format("woff");
  font-display: swap;
}
@font-face{
  font-family:"FilsonSoftBook";
  src:url("/fonts/5210617/67243edf-db55-4c1d-92e2-ee4c33fa633b.woff2") format("woff2"),url("/fonts/5210617/de0124a9-743a-4757-bfff-c509c5970ee7.woff") format("woff");
  font-display: swap;
}

$sans-font                 : "Open Sans", sans-serif;
$soft-font                 : "FilsonSoftBook", sans-serif;
$soft-font-bold            : "FilsonSoftMedium", sans-serif;
$serif-font                : Times, serif;
$fixed-font                : monospace, sans-serif;
$mono-font                 : $fixed-font;

$base-font-size            : 17px;
$base-line-height          : 29px;
$relative-font-sizing      : true;
$round-to-nearest-half-line: true;

$base-font-family          : $sans-font;

$heading-font              : $sans-font;
$heading-font-cute         : 'chin_up_buttercup_cutecapsRg';
$h1-font-size              : 2.25em;
$h2-font-size              : 1.5em;
$h3-font-size              : 1.25em;
$h4-font-size              : 1em;
$h5-font-size              : 1em;
$h6-font-size              : 0.83em;


// CSS3 Mixin Defaults
// ----------------------------------------------------------------
$default-border-radius: 3px;

// Text Shadow
$default-text-shadow-color   : rgba(0,0,0,.25);
$default-text-shadow-h-offset: 0;
$default-text-shadow-v-offset: 1px;
$default-text-shadow-blur    : 1px;

// Box Shadow
$default-box-shadow-color   : rgba(0,0,0,.25);
$default-box-shadow-h-offset: 0;
$default-box-shadow-v-offset: 1px;
$default-box-shadow-blur    : 3px;
$default-box-shadow-spread  : 0;
$default-box-shadow         : $default-box-shadow-color $default-box-shadow-h-offset $default-box-shadow-v-offset $default-box-shadow-blur $default-box-shadow-spread;

// Transitions
$default-transition-duration: .25s;

// Buttons
$default-button-bg:         palette(purple);
$default-button-text-color: white;

// Icons
$icon-circle-width: 70px;

// Header
$back-button-width: 2.35rem;


// Grid
// ----------------------------------------------------------------

$gutter-width: 18px;
$container-padding: 1em;



// Breakpoints
// ----------------------------------------------------------------

$med    : 480px;
$med-lg : 740px;
$lg     : 900px;
$xl     : 1170px;


// Avatars (sizes used in multiple SCSS files)
// ----------------------------------------------------------------
$avatar-xl: 142px;
$avatar-lg: 96px;
$avatar-md: 48px;
$avatar-sm: 36px;


// Z-Index
// ----------------------------------------------------------------
$z-indices: (
  nav-dropdown: 100,
  cookie-consent: 200,
  mylists: 300
);
@function z-index($el) {
  @return map-get($z-indices, $el);
}