$toggle-on-color:  #7fb118 !default;
$toggle-off-color: #f0f0f0 !default;

// Toggle Switch
// ----------------------------------------------------------------
// ref. https://codepen.io/mallendeo/pen/eLIiG

.toggle-switch {
  display: none;
  
  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .toggle-button {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }
  
  + .toggle-button {
    outline: 0;
    display: inline-block;
    vertical-align: middle;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
      line-height: 2.5em;
    }
    
    &:after {
      left: 0;
    }
    
    &:before {
      display: none;
    }
  }
  
  &:checked + .toggle-button:after {
    left: 50%;
  }
}

// Theme
.toggle-switch {
  + .toggle-button {
    background: $toggle-off-color;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;

    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all .2s ease;
    }
  }
  
  &:checked + .toggle-button {
    background: $toggle-on-color;
  }
}

// YES/NO
.toggle-switch {
  + .toggle-button:before {
    display: block;
    content: attr(data-toggle-off);
    position: absolute;
    font-size: .7em;
    left: auto;
    right: .7em;
    color: darken($toggle-off-color, 30);
    width: auto;
  }
  &:checked + .toggle-button:before {
    content: attr(data-toggle-on);
    left: .7em;
    right: auto;
    color: #fff;
  }
}

// truncated toggle switch label
.toggle-switch-label {
  display: inline-block;
  vertical-align: middle;
}