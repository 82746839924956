.content-features-grid {
  @include breakpoint($lg) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8em 6em;
  }

  > * {
    position: relative;

    // Ensure content appears above bg-circle
    > * {
      position: relative;
      z-index: 1;
    }

    @include breakpoint(max-width $lg) {
      display: flex;
      flex-direction: column-reverse;

      & + * {
        margin-top: 5em;
      }
    }
  }  

  h2 {
    font-size: 4em;
  }
  img {
    margin: auto;
    max-width: 95%;

    @include breakpoint(max-width $lg) {
      max-width: 80%;
    }
  }

  // BG Circles
  .bg-circle {
    display: none;
    opacity: .1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;

    @include breakpoint(765px) {
      display: block;
    }
  }

  // BG Circle placement
  > :nth-child(1) .bg-circle { top: 20%; left: 30%; transform: scale(2.5); }
  > :nth-child(2) .bg-circle { top: 50%; left: 50%; transform: scale(3.3); }
  > :nth-child(3) .bg-circle { top: 10%; left: 90%; transform: scale(2.1); }
  > :nth-child(4) .bg-circle { top: 30%; left: 93%; transform: scale(2); }
  > :nth-child(5) .bg-circle { top: 50%; left: 92%; transform: scale(2.8); }
  > :nth-child(6) .bg-circle { top: 40%; left: 70%; transform: scale(3.6); opacity: .2; }

  // Underline and BG Circle Colors
  > :nth-child(1) :where(.bg-circle, .squiggle-underline) { fill: palette_new(teal); }
  > :nth-child(2) :where(.bg-circle, .squiggle-underline) { fill: palette_new(green); }
  > :nth-child(3) :where(.bg-circle, .squiggle-underline) { fill: palette_new(levels,5); }
  > :nth-child(4) :where(.bg-circle, .squiggle-underline) { fill: palette_new(purple); }
  > :nth-child(5) :where(.bg-circle, .squiggle-underline) { fill: palette_new(teal,dark); }
  > :nth-child(6) :where(.bg-circle, .squiggle-underline) { fill: palette_new(levels,3); }
}