// Avatars
// ----------------------------------------------------------------

$avatar-xl: 142px !default;
$avatar-lg: 96px !default;
$avatar-md: 48px !default;
$avatar-sm: 36px !default;


$avatar-colors: (
  a: #4390a8,
  b: #96c6d5,
  c: #7a0f80,
  d: #7fb118,
  e: #d6332b,
  f: #fd7bc6,
  g: #f99a26,
  h: #49ac97,
  i: #6a8627,
  j: #e6c700,
  k: #5d98dd,
  l: #af63ca,
  m: #27d7b1,
  n: #c7c3ab,
  o: #4390a8,
  p: #96c6d5,
  q: #7a0f80,
  r: #7fb118,
  s: #d6332b,
  t: #fd7bc6,
  u: #f99a26,
  v: #49ac97,
  w: #6a8627,
  x: #e6c700,
  y: #5d98dd,
  z: #af63ca
);

@function avatar-color($letter: 'a') {
  @return map-get($avatar-colors, $letter);
}

.avatar {
  width: $avatar-md;
  height: $avatar-md;
  background: avatar-color(a);
  color: #fff;
  font-size: 1.6em;
  line-height: $avatar-md;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  overflow: hidden;
  text-decoration: none;

  img {
    display: block;
  }
}

.avatar-sm {
  @extend .avatar;
  width: $avatar-sm;
  height: $avatar-sm;
  font-size: 1.3em;
  line-height: $avatar-sm;
}

.avatar-lg {
  @extend .avatar;
  width: $avatar-lg;
  height: $avatar-lg;
  line-height: $avatar-lg;
}

.avatar-xl {
  @extend .avatar;
  width: $avatar-xl;
  height: $avatar-xl;
  line-height: $avatar-xl;
}

.avatar-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.2em;
  height: 7.4em;
  font-size: 1em;
  max-width: 122px;
  max-height: 126px;

  &[class*=avatar-color] {
    background-color: transparent;
  }

  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
  }

  circle {
    fill: #6d246b;
  }

  .avatar-initials {
    font-size: 3.2em;
    color: #fff;
    position: relative;
    z-index: 10;
    font-weight: bold;
    font-family: $soft-font-bold;
  }
}

@each $letter, $color in $avatar-colors {
  .avatar-color-#{$letter} {
    background-color: $color;

    circle {
      fill: $color;
    }
  }
}