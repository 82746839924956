// Avoid first paragraph having top margin
.fr-box.fr-basic .fr-element > :first-child {
  margin-top: 0;
}

// Content submitted by Froala
.fr-view {
  p + p {
    margin-top: 1em;
  }
}