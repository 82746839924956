// My Account
// ----------------------------------------------------------------

// Account Home page
.account-home {
  h2 {
    color: palette_new(purple);
  }
}

@include breakpoint($lg) {
  // Pull the user card up and move the title over to make space
  .account-home .banner-title { padding-left: 8em; }
  .account-home .user-card { margin-top: -8rem; }

  .account-home-container {
    display: grid;
    grid-template-columns: 5fr 7fr;
    gap: 2rem;
  }
  .account-home-content {
    grid-column: 1/2;
  }
  .account-home-launchpad {
    grid-column: 2/3;
  }
}

.account-home-content {
  background-color: palette_new(grey, xx-light);
  border-radius: .5em;
  margin-bottom: 1em;

  .upcoming-events {
    padding: 0 1.5rem;
    margin-bottom: 1em;
  }
  .upcoming-events ul {
    font-size: 0.85em;
  }
  .upcoming-events a {
    color: palette_new(purple)
  }
  
  .date-square .day {
    background-color: #fff;
  }
}

.launchpad-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  gap: 1rem;
}

// User Card
// ----------------------------------------------------------------
$user-card-padding: 1.5rem;

.user-card {
  max-width: 30rem; // Temp
  background-color: #f2ffff;
  border-radius: .5em;
  box-shadow: 2px 5px 8px rgba(0,0,0,.3);
  margin-left: auto;
  margin-right: auto;
  color: $base-font-color;

  a:not(.button) {
    color: palette_new(teal);
  }
}
.user-card-header {
  background-color: #fff;
  padding: $user-card-padding*.5 $user-card-padding;
  position: relative;
  border-radius: .5em .5em 0 0;

  &::after {
    content: '';
    background: transparent url(/images/pages/account/user-card-squiggle.svg) 0 0 / contain no-repeat;
    position: absolute;
    bottom: -2.75em;
    left: 0;
    width: 100%;
    height: 3em;
  }
}
.user-edit-link {
  text-decoration: none;
}
.user-edit-links {
  position: absolute;
  bottom: -1.3em;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  > *:not(:first-child) { margin-left: .5em; }

  .button {
    font-size: 0.75em;
    padding: .25em 1em;
  }
}
.user-card-name {
  margin: 0;
  color: palette_new(purple);
  font-size: 1.8em;
}
.user-card-pronouns {
  font-size: 0.85em;
  color: #a6a6a6;
  margin: 0 0 .25em;
}
.user-card-body {
  padding: $user-card-padding*.5  $user-card-padding $user-card-padding;
  font-size: .8em;

  h3 {
    text-transform: uppercase;
    color: palette_new(teal);
    font-size: 1.05em;
    margin: 1.5em 0 .35em;
    font-weight: bold;
  }
  h3 + p {
    margin-top: 0;
  }
}
.user-social-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > li { margin: 0 0 .5em 0; padding: 0; }
  li:not(:last-child) {
    margin-right: 1.75em;
  }
}
.user-social-badge {
  display: flex;
  gap: .5em;
  text-decoration: none;
  color: #fff;

  &:hover {
    color: #fff;
    opacity: 0.8;
  }

  img { display: block; }
}
.user-social-badge-location {
  background-color: palette_new(green);
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding-right: .75em;
  font-size: 0.85em;

  img {
    border-right: 1px solid rgba(255,255,255,.5);
    margin-right: .75em;
  }
}


// Badges
// ----------------------------------------------------------------

.badges-small,
.badges-med {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  > :not(:first-child) {
    margin-left: .5em;
  }

  img {
    width: 32px;
  }

  .badges-more {
    font-size: 0.7em;
    width: 32px;
    line-height: 1;
    font-weight: bold;
    color: #aaa;
    padding-left: .5em;
  }
}
.badges-small img { width: 32px; }
.badges-med img   { width: 48px; }

.challenge-badge {
  display: inline-block;
  vertical-align: middle;
}

.challenge-badge-form {
  label {
    border-radius: $default-border-radius;
    padding: .25em 1em;
    transition: all .15s;
    cursor: pointer;
    font-size: .9em;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      background-color: palette(teal, x-light);
    }
  }

  .checkboxes {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > li {
      flex: 1 1 30%;
      min-width: 300px;
      margin: .25em;
    }
  }

  input[type=checkbox] {
    display: none;
  }

  li.hidden {
    display: none;
  }

  .challenge-badge {
    width: 2.5em;
    height: 2.5em;
    margin-right: .5em;
    opacity: .25;
    transform: scale(.75);
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-items: center;

    img {
      max-height: 2.5em;
      max-width: 2.5em;
    }
  }

  input[type=checkbox]:checked ~ .challenge-badge {
    opacity: 1;
    transform: none;
  }
  input[type=checkbox]:checked ~ .challenge-badge::after {
    border: 1px solid palette(teal);
  }

  input[type=checkbox]:checked ~ .challenge-name {
    font-weight: bold;
    color: #222;
  }

  .my-badges {
    justify-content: center;
  }
}

.challenge-badge-add-form .challenge-badge::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  opacity: .5;
  border-radius: $default-border-radius;
  width: 100%;
  height: 100%;
}


// Blog post list
// ----------------------------------------------------------------
.launchpad-blog-post {
  position: relative;
  font-size: .8em;

  img {
    border-radius: .5em;
    max-width: 96px;
  }

  h3, p {
    margin: 0;
  }
  h3 a::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Account Profile
// ----------------------------------------------------------------
.account-profile {
  .main {
    padding-bottom: 0;
  }
  // hide the breadcrumbs
  .header-secondary {
    display: none;
  }
}


// My Lists
// ----------------------------------------------------------------
.my-list-item {
  list-style: none;
  line-height: 1.4;
  margin: 0;

  & + .my-list-item {
    margin-top: 1.5em;
  }

  &:has(.list-remove) > a {
    text-decoration: line-through;
    opacity: .3;
  }
  &:has(.list-remove:not(.list-mark-done) input:checked) > a {
    text-decoration: none;
    opacity: 1;
  }

  .item-section {
    font-weight: bold;
    font-size: .7em;
    display: block;
    margin-top: -.25em;
  }
}

.list-remove {
  display: inline-block;

  label {
    font-weight: normal;
    cursor: pointer;
    color: $quiet-font-color;

    &:hover {
      text-decoration: underline;
    }

    &::after {
      content: 'Undo';
    }
  }
  span {
    height: 1px; 
    width: 1px;
    overflow: hidden;
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  input[type=checkbox] {
    display: none;
  }

  // Checked (ie. the default)
  input[type=checkbox]:checked ~ label {
    &::after {
      content: 'Remove from list';
    }
  }
}

// Needs to be opposite as this will be unchecked by default
.list-remove.list-mark-done  label::after {
  content: 'Mark as Completed';
}
.list-remove.list-mark-done input[type=checkbox]:checked ~ label::after {
  content: 'Undo';
}