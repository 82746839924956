// Layout
// ----------------------------------------------------------------

* {
  box-sizing: border-box;
}

html {
  background: $footer-bg-color;
  line-height: 1.7;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  position: relative;
}

.mm-wrapper {
  background-color: #fff;
}

.container {
  @include container;
  padding: 0 $container-padding;

  @include breakpoint($xl) {
    padding: 0;
  }
}

.main {
  @include padding-trailer(2);
}

@include breakpoint(max-width $med) {
  .hide-mobile {
    display: none;
  }
}


// Stripes
// ----------------------------------------------------------------
.stripe {
  --stripe-padding: 3.5em;

  padding-top: var(--stripe-padding);
  padding-bottom: var(--stripe-padding);
  clear: both;
}

.stripe-thin {
  --stripe-padding: 1.7em;
}

.stripe-shadow {
  border-bottom: 1px solid rgba(255,255,255,.5);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.stripe-bordered-thick {
  border-top: 2px solid #96C6D5;
  border-bottom: 2px solid #96C6D5;
}

// Backgrounds
// ----------------------------------------------------------------

.content-sidebar,
.content-sidebar > .container {
  @include breakpoint($lg) {
    background: linear-gradient(to right, white 67.6068376%, palette(teal, x-light) 67.6068376%);
  }
}
.main.content-sidebar { padding-bottom: 0; }
.main.content-sidebar > .container { padding-bottom: 3rem; }


.content-tabs,
.content-tabs > .container {
  @include breakpoint($lg) {
    background: linear-gradient(to right, #f5f5f5 67.6068376%, #e4eced 67.6068376%);
  }
}


// Content
// ----------------------------------------------------------------

@include breakpoint($lg) {

  // Centered Content Blocks
  .content-narrow,
  .content-x-narrow,
  .content-med,
  .content-centered,
  .content-intro {
    margin-inline: auto;
  }
  .content-med,
  .content-centered {
    max-width: 57rem;
  }
  .content-narrow,
  .content-intro {
    max-width: 45rem;
  }
  .content-x-narrow {
    max-width: 34rem;
  }
}

// Content-Split and Variations
// ----------------------------------------------------------------

.l-subnav-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: $gutter-width;

  .subnav { grid-row: 2/3; }
  .content { grid-row: 1/2; }
}

@include breakpoint($lg) {
  .content-split,
  .l-content-sidebar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a b";
    gap: $gutter-width;
  }

  .content-split-reverse {
    > :nth-child(1):not(script), > .l-content-sidebar-sidebar { grid-area: b; }
    > :nth-child(2):not(script), > .l-content-sidebar-content { grid-area: a; }
  }
  
  .l-content-sidebar {
    grid-template-columns: 7fr 5fr;
  }

  .l-subnav-content {
    grid-template-columns: 2fr 6fr 1fr;
    grid-template-areas: "a b c";

    .subnav { grid-area: a; display: block; padding-right: 2em; }
    .content { grid-area: b; }
  }
}

