// Tabs
// ----------------------------------------------------------------

$stripe-tabs-bg: #f5f5f5 !default;
$stripe-tabs-border: #d7d7d7 !default;

.stripe-tabs {
  @include rhythm(0,1,0,1);
  background: $stripe-tabs-bg;

  .banner + & {
    margin-top: -1.5em; // remove space between this and the banner
  }

  .banner-title + &-inset {
    margin-top: -4em; // inset this within the banner above
    background-color: #f5fbfc;
    border-bottom: 1px solid #c8e2e8;

    .tabs .active {
      position: relative;
      top: 1px; // erase bottom border by covering it up
      a { border-color: #c8e2e8; }
    }
  }
}

.tabs {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: .5em;
  }
}

:where(.tabs):not(.tabs-unstyled) {
  li {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    @include rhythm(0,.25,.25,0);
    transition: none;
    border-radius: 3px 3px 0 0;
    font-size: 15px;
    padding-left: 1em;
    padding-right: 1em;
    display: block;
    text-transform: uppercase;
    color: palette(grey);
    text-decoration: none;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: palette(teal);
    }

    .new-item {
      font-size: .7em;
      font-weight: 600;
      color: palette(orange, light);
      text-transform: uppercase;
      padding-left: .75em;
    }

  }
  .active a {
    background: white;
    border: 1px solid #e4eced;
    border-bottom: none;
    color: palette(green);
  }
}

.tab-pane {
  display: none;
  visibility: hidden;

  &.active {
    display: block;
    visibility: visible;
  }
}

.tabs-centered {
  ul {
    justify-content: center;
  }
}

.tab-classes {
  background-color: #f1fafa;
  margin-bottom: 2em;

  > .container {
    display: flex;
  }
  ul {
    padding-top: 2em;
    flex-grow: 1;
  }
  li {
    margin: 0;
  }

  
  a {
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .active a {
    color: palette_new(teal);
  }

  .tabs-nav-back {
    align-self: center;
    color: palette_new(teal);
    text-transform: none;
  }
}

.tabs-large {
  --border-color: #00b9ba;
  border-bottom: 1px solid var(--border-color);
}
.tabs-large ul {
  position: relative;
  top: 1px;
}
.tabs-large a {
  font-size: 1.2em;
  line-height: 1.2;
  text-transform: none;
  padding: .6em 1.5em;
  border-radius: .25em .25em 0 0;
  border-bottom: 1px solid var(--border-color);
}
.tabs-large .active a {
  color: palette_new(teal);
  border-color: var(--border-color);
  border-bottom: none;
  box-shadow: 0 1px 0 #fff;
}