// Images
// ----------------------------------------------------------------
$img-margin: 1em !default;
$img-border: #afbfd0 !default;

img {
  max-width: 100%;
  height: auto !important;

  &.right {
    float: right;
    margin: 0 0 $img-margin $img-margin;
  }
  &.left {
    float: left;
    margin: 0 $img-margin $img-margin 0;
  }
  &.border {
    border: 1px solid $img-border;
  }
  &.thumbnail {
    box-shadow: rgba(0,0,0,.5) 0 2px 5px;
    border: 1px solid rgba(255,255,255,.5);
  }
  &.photo-frame {
    @extend .thumbnail;
    border: 4px solid #fff;
  }
}
a img {
  display: block;
}

// Figure tags and alignment
$img-align-bp: 650px;
figure {
  --image-margin-inline: 1em;
}
figure > img {
  display: block;
  margin: 0 auto;
}

.fig-full {
  display: block;
  text-align: center;

  > img {
    margin: 0 auto;
    max-height: 400px;

    @include breakpoint($img-align-bp) {
      max-height: none;
    }
  }
}
.fig-right {
  @extend .fig-full;

  @include breakpoint($img-align-bp) {
    float: right;
    max-width: 300px;
    margin: 0 0 $img-margin $img-margin;
  }
}
.fig-left {
  @extend .fig-full;

  @include breakpoint($img-align-bp) {
    float: left;
    max-width: 300px;
    margin: 0 $img-margin $img-margin 0;
  }
}

figure.image-style-align-left {
  float: left;
  margin-right: var(--image-margin-inline);
}
figure.image-style-align-right {
  float: right;
  margin-left: var(--image-margin-inline);
}
figure.image-style-block-align-left > img   { margin-right: auto; }
figure.image-style-block-align-right > img  { margin-left: auto; }

figcaption {
  margin-top: .25em;
  text-align: center;
  font-style: italic;
  font-size: .9em;
  color: $quiet-font-color;
}

// Figures inside specific layouts
.subnav + .content > .fig-right {
  @include breakpoint($lg) {
    margin-right: -12%;
  }
}

.content-sidebar .content .fig-right img {
  @include breakpoint($img-align-bp) {
    max-width: 230px;
  }
}

.shallow-flat-shadow,
.deep-flat-shadow {
  --box-shadow-color: #e2f3f5;
  border-radius: 8px;
  border: 1px solid #96c6d5;
  box-shadow: var(--box-shadow-color) 20px 18px 0;
  margin-right: 20px;
  margin-bottom: 18px;
  overflow: hidden;

  > * {
    margin: 0 !important;
  }
}

.shallow-flat-shadow {
  box-shadow: var(--box-shadow-color) 9px 8px 0;
}

// Style Utility Classes
.has-shadow {
  box-shadow:
    0 0.4px 0.3px rgba(0, 0, 0, 0.043),
    0 1.1px 0.9px rgba(0, 0, 0, 0.067),
    0 2.1px 1.8px rgba(0, 0, 0, 0.085),
    0 3.5px 3.7px rgba(0, 0, 0, 0.1),
    0 7px 10px rgba(0, 0, 0, 0.11)
  ;
}
.has-shadow-flat {
  box-shadow: rgba(0,0,0,.25) 9px 8px 0;
}


.rounded {
  border-radius: 3px;
  overflow: hidden;
}

// Transformation Utility Classes
.flip-h             { transform: scaleX(-1); }
.flip-h-rotate-90-r { transform: rotate(90deg) scaleX(-1); }
.flip-v             { transform: scaleY(-1); }
.flip-v-rotate-90-r { transform: rotate(90deg) scaleY(-1); }
.rotate-180-l       { transform: rotate(-180deg); }
.rotate-90-r        { transform: rotate(90deg); }
.rotate-90-l        { transform: rotate(-90deg); }
