$icon-circle-width: 70px !default;


// SVG Sprite
// ----------------------------------------------------------------

.icon {
  font-size: 1.3em;
  width: 1em;
  height: 1em;
  // fill: $icon-color;
  vertical-align: middle;
}

.icon + span {
  vertical-align: middle;
}

.icon-large {
  font-size: 2em;
}


// Icon Colors
// ----------------------------------------------------------------
$icon-palette: (
  post: palette(purple),
  reading: palette(green),
  audio: palette(aquamarine),
  comment: palette(teal),
  video: palette(red),
  announcements: palette(red),
  official: palette(purple),
  featured: palette(purple),
  conversation: palette(orange)
);

@each $iconType, $color in $icon-palette {
  .icon-#{$iconType} {
    fill: $color;
  }
}


// Icon-Circles
// ----------------------------------------------------------------

.icon-circle {
  height: $icon-circle-width;
  width: $icon-circle-width;
  border-radius: 100%;
  display: flex;

  .icon {
    margin: auto;
    font-size: ($icon-circle-width * .6);
    fill: white;
  }
}


// Alt Icon Shapes
// ----------------------------------------------------------------

.icon-one-star,
.icon-two-stars,
.icon-three-stars {
  width: 1.5em;
  height: 1em;
}