
// Override Hubspot Popup Styles to layout vertically with full-width images
body {
  .leadinModal.leadinModal-theme-default.leadinModal-v3 .leadin-content-body #leadin-content-form-wrapper, .leadinModal.leadinModal-theme-default.leadinModal-v3 .leadin-content-body .leadin-preview-wrapper {
    padding: 1rem 1.5rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .leadinModal.leadinModal-theme-default.leadinModal-v3 .dyno-image {
    margin: 0;
    float: none;
    width: 100%;
  }

  .leadinModal.leadinModal-v3 .dyno-image img {
    max-width: 100%;
  }

  .leadinModal.leadinModal-theme-default.leadinModal-v3.leadinModal-preview h4 {
    margin: 0;
    text-align: center;
    font-size: 1.5em;
  }

  .leadinModal.leadinModal-theme-default.leadinModal-v3.leadinModal-preview .advance-wrapper {
    margin: 0;
  }

  .leadinModal .leadin-preview-wrapper .advance-wrapper .leadin-button {
    margin: 0;
    font-size: 1.5em;
  }


  .leadinModal-reset .clearfix-image {
    display: none;
  }

  .leadinModal-hide-outline.leadinModal-description-2536612 {
    display: none;
  }
}