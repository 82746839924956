// Brave Writer Theme for Tooltipster
// ----------------------------------------------------------------

$tooltip-background-color: #fff;
$tooltip-border-color: #c6cfd9;

$tooltip-background-color-dark: rgba(0,0,0,.9);


.has-tooltip {
  cursor: pointer;
}

.tooltipster-sidetip.tooltipster-light.tooltipster-light-customized {
  .tooltipster-box {
    box-shadow: rgba(0,0,0,.25) 0 1px 1px,
                rgba(0,0,0,.3) 0 2px 10px;
    background: $tooltip-background-color;
    border: 1px solid $tooltip-border-color;
  }

  .tooltipster-content {
    color: $base-font-color;
    text-align: left;
    font-size: .85em;
    line-height: 1.4;
    padding: 1em;

    > * > :first-child { margin-top: 0; }
    > * > :last-child { margin-bottom: 0; }
  }

  .tooltipster-arrow {
    bottom: -1px; // compensate for thinner border
  }
  .tooltipster-arrow-border {
    border-top-color: $tooltip-border-color;
  }
  .tooltipster-arrow-background {
    border-top-color: $tooltip-background-color;
    top: -2px;
  }

  &.tooltipster-bottom {
    .tooltipster-arrow {
      top: -1px;
    }
    .tooltipster-arrow-border {
      border-bottom-color: $tooltip-border-color;
    }
    .tooltipster-arrow-background {
      border-bottom-color: $tooltip-background-color;
      top: 1px;
    }
  }
}


.tooltipster-sidetip.tooltipster-default {
  .tooltipster-box {
    box-shadow: rgba(0,0,0,.25) 0 1px 1px,
                rgba(0,0,0,.3) 0 2px 10px;
    background: $tooltip-background-color-dark;
  }

  .tooltipster-content {
    color: #fff;
    font-size: .8em;
    line-height: 1.4;
    padding: .5em .75em;

    > * > :first-child { margin-top: 0; }
    > * > :last-child { margin-bottom: 0; }
  }

  .tooltipster-arrow-background {
    border-top-color: $tooltip-background-color-dark;
  }

  &.tooltipster-bottom {
    .tooltipster-arrow-background {
      border-bottom-color: $tooltip-background-color-dark;
    }
  }
}

