// Staff Page
// ----------------------------------------------------------------

$staff-photo-border-radius: 6px;

$video-v-offset: 2em;

.staff-video-stripe {
  background: palette(purple) url(/images/pages/staff/banner-pixie-dust.png) 50% 100% / cover no-repeat;
  color: white;
  margin-top: -9px; // remove the light grey stripe beneath the breadcrumbs

  h1 {
    @include rhythm(0,.5,0,0);
    text-align: center;
    color: #fff;
    font-weight: 300;
  }

  .staff-video {
    box-shadow: rgba(0,0,0,.5) 0 4px 12px;
    border: 1px solid rgba(255,255,255,.6);
    position: relative;
    top: $video-v-offset;
    margin-inline: auto;
    max-width: 840px;

    .video-embed {
      margin-bottom: 0;
    }
  }
}

.staff-first-stripe {
  @include rhythm(0,3,1,2);
}

.staff-first {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint($med) {
    flex-direction: row;
  }

  @include breakpoint($lg) {
    align-items: center;
    margin: 0 1em;
  }

  h2 {
    color: palette(purple);
    margin: 0;
  }
  h3 {
    color: palette(purple);
    opacity: 0.5;
    margin-top: 0;
    font-style: italic;
  }

  > img {
    border-radius: $staff-photo-border-radius;
    margin-right: 1em;
    margin-bottom: 1em;

    @include breakpoint($med) {
      width: 33%;
      margin-bottom: 0;
    }
    @include breakpoint($lg) {
      margin-right: 2em;
    }

  }
}

.staff-grid {
  @include clearfix;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.staff-grid-item {
  @include trailer(2);

  @include breakpoint($med) {
    // @include gallery(6);
    width: 50%;
    padding: 0 .5em;
  }
  @include breakpoint($lg) {
    // @include gallery(4);
    width: 33%;
  }

  h2 {
    @include rhythm(.5, 0, 0, 0);
  }
  h3 {
    margin-top: 0;
    color: $quiet-font-color;
    font-style: italic;
  }

  img {
    @include trailer(.5);
    border-radius: $staff-photo-border-radius;
    margin: 0 auto;
    display: block;

    @include breakpoint($med) {
      max-width: 75%;
    }
  }

  p {
    font-size: .9em;
    line-height: 1.6;

    @include breakpoint($med) {
      padding: 0 .75em;
    }
  }
}