@media print {

  @page {
    size: letter;
    margin: .5in 1.125in .5in 1.125in !important;
  }
  
  body {
    font-size: 10pt;
    line-height: 1.5;
  }

  .header-main,
  .header-secondary,
  .footer-main,
  .back-button,
  .announcement-bar,
  .no-print {
    display: none !important;
  }

  .banner-title h1 {
    color: #000 !important;
  }
}