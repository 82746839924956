// Item Card
// for products and online classes
// ----------------------------------------------------------------
.item-card {
  --item-card-padding: 1em;
  --item-card-border-radius: 4px;

  background: #fff;
  border: 1px solid #dfdfe0;
  border-radius: var(--item-card-border-radius);
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all .2s;

  &:not(.no-shadow) {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
  }

  &:has(.item-card-link):hover {
    transform: translateY(-5px);
  }

  a:not(.button) {
    display: block;
    text-decoration: none;
    // position: relative;
    z-index: 1; // ensure non-breakout links are above breakout
    color: inherit;
  }
  .item-card-link {
    position: static;

    &::before { // breakout link, ref. https://css-tricks.com/breakout-buttons/
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  .badge {
    font-size: 0.6em;
    margin: 0;
  }
}

.item-card-image-banner {
  position: relative;
  aspect-ratio: 16/9;
}
.item-card-image {
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: var(--item-card-border-radius) var(--item-card-border-radius) 0 0;
  width: 100%;
  display: block;
}
.item-card-image-popout {
  position: absolute;
  top: -1em;
  left: 50%;
  max-width: 50%;
  transform: translateX(-50%) rotate(-2deg);
}

.item-card-title {
  text-transform: uppercase;
  font-family: $soft-font-bold;
  font-size: 1em;
  line-height: 1.2;
  margin: 0.75em 0 .25em;
  color: palette_new(purple);
}

.item-card-body {
  padding: 0 var(--item-card-padding);
}

.item-card-footer {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: .25em;
  padding: .5em var(--item-card-padding) var(--item-card-padding);

  &.text-center {
    justify-content: center;
  }
}

.item-card.item-card-horizontal {
  display: grid;
  grid-template-columns: 2fr 5fr;

  > header          { grid-area: 1/1/3/2; }
  .item-card-body   { grid-area: 1/2/2/3; }
  .item-card-footer { grid-area: 2/2/3/3; font-size: .9em; }

  .item-card-image {
    border-radius: var(--item-card-border-radius) 0 0 var(--item-card-border-radius);
  }
  .item-card-image-popout {
    top: -.3em;
  }
}