// Breadcrumbs
// ----------------------------------------------------------------

.breadcrumbs {
  --breadcrumbs-bg: #f1ffff;
  --breadcrumbs-color: #00B9BA;

  margin-inline: -1em;
  overflow: hidden;
  transition: all .2s;
  position: relative;

  &.open {
    background: var(--breadcrumbs-bg);
    border-block-end: 1px solid #CAEAEB;
    
    ul {
      padding-block: .5em;
    }
    li:not(:last-child) {
      height: auto;
    }
  }

  ul {
    margin: 0;
    padding: .25em 1em;
    list-style: none;
    display: flex;
    flex-direction: column;
    transition: all .2s;
  }

  li {
    display: flex;
    gap: .5em;
    align-items: center;
    padding: 0;
    overflow: hidden;
  }

  li:not(:last-child) {
    height: 0;
  }

  li:not(:first-child):not(:last-child)::before {
    content: url('/images/breadcrumbs-ellipsis.svg');
  }

  li:last-child::before {
    content: url('/images/breadcrumbs-arrow-left.svg');
  }

  a {
    color: var(--breadcrumbs-color);
    text-decoration: none;
    display: block;
    font-size: .9em;
    line-height: 2.4;

    &:hover {
      color: var(--breadcrumbs-color);
      text-decoration: underline;
    }
  }

  a span {
    display: inline-block;
    padding-left: .5em;
  }

  svg {
    fill: var(--breadcrumbs-color);
  }
  

  @include breakpoint($lg) {
    --breadcrumbs-bg: transparent;
    --breadcrumbs-color: #fff;

    margin-inline: 0;

    &.open {
      border-block-end: none;
      
      ul {
        padding-block: 0;
      }
    }

    ul {
      padding: 0;
      flex-direction: row;
    }
  
    li::before {
      content: '';
    }

    li:not(:last-child) {
      height: auto;
    }
  
    li:not(:first-child):not(:last-child)::before {
      content: url('/images/breadcrumb-right.svg');
    }
    li:last-child::before {
      content: url('/images/breadcrumb-right.svg');
    }
  
    li:first-child span {
      display: none;
    }
    li:first-child a {
      padding-left: 0;
    }
  
    a {
      @include ellipsis(16em);
      padding: 0 1.25em;
      font-size: .76em;
      line-height: 2.2;
    }
  }
  
}

.breadcrumbs-toggle {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 2.4em;
  height: 2.4em;
  position: absolute;
  top: 0;
  right: 0;

  @include breakpoint($lg) {
    display: none;    
  }

  &::before {
    content: url('/images/breadcrumbs-open.svg');
    width: 16px;
    height: 16px;
    display: block;
  }

  span {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
.breadcrumbs.open .breadcrumbs-toggle::before {
  content: url('/images/breadcrumbs-close.svg');
}