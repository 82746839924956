// HTML5 elements
// ----------------------------------------------------------------
video,
audio {
  max-width: 100%;
}
audio {
  width: 100%;
}

.video-embed,
.video-container {
  @include trailer;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.video-embed-hd {
  width: 100%;
  aspect-ratio: 16/9;

  iframe,
  object,
  embed {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

// .video-background {
//   position: relative;
//   background: #000;
//   min-height: 10em;
// }

// .video-foreground,
// .video-background iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
// }

// @media (min-aspect-ratio: 16/9) {
//   .video-foreground { height: 300%; top: -100%; }
// }
// @media (max-aspect-ratio: 16/9) {
//   .video-foreground { width: 300%; left: -100%; }
// }

.video-background {
  width: 100%;
  // height: 350px; // todo: responsive heights to show more of video?
  padding-bottom: 30px;
  padding-top: 30%;
  opacity: 1;
  position: relative;
  overflow: hidden;
}

.video-foreground {
  padding-top: 56.25%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 45px;
  }
}

.video-overlay {
  background: black;
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}