.unstyled-list {
  @include unstyled-list;
}

// Similar to unstyled, but doesn't override internal margin/paddings
.no-bullets {
  margin: 0;
  padding: 0;
  list-style: none;
}

.link-list {
  @include list-reset;

  a {
    display: block;
    text-decoration: none;
    padding: .5em;
    border-radius: $default-border-radius;
    transition: all .25s;

    &:hover {
      background-color: $hover-color;
    }
  }
}
.link-list-bordered {
  > li + li {
    border-top: 1px solid #e5f0f2;
  }
}

.pipe-nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  > li + li {
    border-left: 1px solid rgba(0,0,0,.2); 
  }

  a {
    display: inline-block;
    padding-inline: 1em;
  }
  li:first-child a {
    padding-inline-start: 0;
  }
}


.chevron-list {
  @include list-reset;

  li {
    position: relative;
    transition: all .25s;

    &:before {
      content: '\00BB';
      color: palette_new(blue);
      display: inline-block;
      width: 1em;
      position: absolute;
      left: -.65em;
      font-size: 1.2em;
    }
  }

  &.link-list {
    // li:before {
    //   top: .35em;
    // }
    li:hover {
      transform: translateX(.5em);
    }
  }
}

.checklist {
  @include list-reset;

  > li {
    padding-left: 30px;
    margin-bottom: .3em;
    background: transparent url(/images/svg-icons-source/check.svg) 0 0 no-repeat;
  }
}

.divided-list {
  @include list-reset;

  > li {
    padding-top: .5em;
    padding-bottom: .5em;
  }
  > li + li {
    border-top: 1px solid $divider-color;
  }

  &.has-outer-borders {
    border-top: 1px solid $divider-color;
    border-bottom: 1px solid $divider-color;
  }

  &.divider-thick > li + li {
    border-top: 2px solid #d9f5f5;
  }
}

@include breakpoint($med-lg) {
  ul.two-col, ol.two-col {
    column-count: 2;
    column-gap: 3em;
  }
}

// Fancy Bullets
// ul:is(.fancy-bullets, .fancy-checks) {
.fancy-bullets, .fancy-checks {
  @include list-reset;
  list-style: none !important;

  > li {
    padding-left: 30px;
  }
  > li + li {
    margin-top: 0.5em;
  }
}
ul.fancy-bullets > li {
  background: transparent url(/images/bullets.svg#bullet-1) .15em .5em no-repeat;

  &:nth-child(6n-5) { background-image: url(/images/bullets.svg#bullet-1); }
  &:nth-child(6n-4) { background-image: url(/images/bullets.svg#bullet-2); }
  &:nth-child(6n-3) { background-image: url(/images/bullets.svg#bullet-3); }
  &:nth-child(6n-2) { background-image: url(/images/bullets.svg#bullet-4); }
  &:nth-child(6n-1) { background-image: url(/images/bullets.svg#bullet-5); }
  &:nth-child(6n)   { background-image: url(/images/bullets.svg#bullet-6); }
}

// Fancy Checks
ul.fancy-checks > li {
  background: transparent url(/images/bullets-checks.svg#check-1) 0 .35em no-repeat;

  &:nth-child(6n-5) { background-image: url(/images/bullets-checks.svg#check-1); }
  &:nth-child(6n-4) { background-image: url(/images/bullets-checks.svg#check-2); }
  &:nth-child(6n-3) { background-image: url(/images/bullets-checks.svg#check-3); }
  &:nth-child(6n-2) { background-image: url(/images/bullets-checks.svg#check-4); }
  &:nth-child(6n-1) { background-image: url(/images/bullets-checks.svg#check-5); }
  &:nth-child(6n)   { background-image: url(/images/bullets-checks.svg#check-6); }
}


// Illustration Bullets

ul:has(.illustration-list-item) {
  --illustration-size: 60px;
  
  @include list-reset;

  > li + li {
    margin-top: 1.5em;
  }
}
.illustration-list-item {
  display: flex;
  gap: .7em;
  align-items: start;

  &::before {
    content: '';
    background: transparent url(/images/illustrations/book.svg) 50% 50% / contain no-repeat;
    flex: 0 0 var(--illustration-size);
    min-height: var(--illustration-size);
  }

  $illustrations: (
    'balloon',
    'book-pop',
    'book',
    'bullhorn',
    'calendar',
    'campfire',
    'coffee-swirl',
    'coffee-takeout',
    'film-camera',
    'globe',
    'kite',
    'laptop',
    'map-pin',
    'mugs',
    'music-notes',
    'note-paper',
    'paper-airplane',
    'pencil-jar',
    'pencil',
    'special-offer',
    'webinar',
    'whale'
  );
  @each $img in $illustrations {
    &.#{$img}::before { background-image: url(/images/illustrations/#{$img}.svg); }
  }
}


// Icon List
.icon-list {
  --icon-width: 1em;
  --icon-gap: .5em;

  margin: 0 0 0 calc(var(--icon-width) + var(--icon-gap));
  padding: 0;
  list-style: none;

  > li {
    position: relative;
  }

  .icon-wrapper {
    position: absolute;
    left: calc((var(--icon-width) + var(--icon-gap)) * -1);
    text-align: center;
    width: var(--icon-width);
  }
}