.banner-book-shop {

  h1 {
    justify-content: flex-start;
  }
  .banner-intro {
    position: relative;

    p {
      margin: 0;

      @include breakpoint($lg) {
        max-width: 60%;        
      }
    }
    p + p {
      margin-top: 1em;
    }
    img {
      max-width: 31%;
      position: absolute;
      top: -35%;
      right: 5%;
      display: none;

      @include breakpoint($lg) {
        display: block;
      }

      @include breakpoint($xl) {
        top: -65%;        
      }
    }
  }
}

.banner-book .container {
  padding-top: 1em;
  padding-bottom: 1em;

  @include breakpoint($lg) {
    display: grid;
    grid-template-columns: 60px 3fr 8fr;
    gap: 0 2em;
    padding-top: 0;
    padding-bottom: 0;
  }

  .back-button {
    grid-column: 1/2;
    position: static;
    transform: none;
  }
  .back-button-icon {
    background-color: rgba(255,255,255,.2);
    &:hover {
      background-color: rgba(255,255,255,.4);
    }
  }

  .book-image {
    grid-column: 2/3;
    position: relative;
    
    img {
      max-width: 35%;
      margin: -2.5em auto 0;
      display: block;

      @include breakpoint($lg) {
        max-width: 100%;
        position: absolute;
        top: .5em;
        left: 0;
        margin-top: 0;
      }
    }
  }
  h1,
  .data-list {
    grid-column: 3/4;
  }
  h1 {
    text-align: center;
    display: block;
    line-height: 0.8;
    margin: 0;
    padding: .125em 0;

    @include breakpoint($lg) {
      text-align: left;
      padding: .375em 0;
    }
  }
  .data-list {
    margin: 0;
    padding: 2em 0;
  }
}

.data-list {
  > div {
    display: grid;
    grid-template-columns: 2fr 7fr;
    gap: 1em;
  }
  > div + div {
    margin-top: .5em;
  }
  dt {
    text-transform: uppercase;
    color: palette_new(green);
    font-size: 0.9em;
    line-height: 2;
    font-weight: bold;
  }
  dd {
    margin: 0;
  }
  p {
    margin: 0;
  }
  p + p {
    margin-top: 0.5em;
  }
}

.buy-buttons-grid {
  --grid-item-width: 130px;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-item-width), 1fr));
}

.books-grid {
  grid-template-columns: repeat(auto-fit, 150px);
  gap: 2rem;
  justify-content: center;

  img {
    transition: all .2s;
  }
  > a:nth-child(1n):hover { transform: translateY(-.4em) rotate(-2deg); }
  > a:nth-child(2n):hover { transform: translateY(-.4em) rotate(-1deg); }
  > a:nth-child(3n):hover { transform: translateY(-.4em) rotate(3deg); }
  > a:nth-child(4n):hover { transform: translateY(-.4em) rotate(1deg); }
  > a:nth-child(5n):hover { transform: translateY(-.4em) rotate(-3deg); }
  > a:nth-child(6n):hover { transform: translateY(-.4em) rotate(2deg); }
  > a:nth-child(7n):hover { transform: translateY(-.4em) rotate(-.5deg); }
}


.book-list-ages {
  font-size: .7em;
  color: white;
  opacity: .7;
  display: inline-block;
  font-weight: bold;
}
.banner-title .book-list-ages {
  height: 1.2em;
  align-self: end;
  padding-left: .25em;
}

.book-list-box {
  --bg-color: #00b9ba;
  background-color: var(--bg-color);
  text-align: center;
  border-radius: .35em;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  height: 10em;
  width: 100%;
  min-width: 30ch;
  max-width: 40ch;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 6em;
    top: 7em;
    left: 0;
    background-color: rgba(0,0,0,.2);
    background-blend-mode: multiply;
    transition: all .35s;
  }
}
.book-list-box-title {
  font-family: $soft-font;
  font-size: 1.2em;
  line-height: 1.2;
  color: #fff;
  margin: 0.5em;
  flex: 0 0 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.book-list-box-books {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  padding-left: 60px;
}
.book-list-box-books img {
  width: 100px;
  box-shadow: rgba(0,0,0,.2) 0 2px 3px;
  margin-left: -60px;
  position: relative;
  transition: all .1s;

  &:nth-child(1) { top: 0px;  z-index: 100; }
  &:nth-child(2) { top: 5px;  z-index: 90; transition-duration: .1s; }
  &:nth-child(3) { top: 10px; z-index: 80; transition-duration: .2s; }
  &:nth-child(4) { top: 15px; z-index: 70; transition-duration: .3s; }
  &:nth-child(5) { top: 20px; z-index: 60; transition-duration: .4s; }
}

.book-list-box:hover::after {
  top: 6em;
}
.book-list-box:hover img {
  top: -5px;
}


// Grid/Scroller toggle
.grid-scroller {
  position: relative;

  --scroller-arrow-width: 55px;
}
.grid-scroller-title,
.grid-scroller-items {
  width: calc(100% - (var(--scroller-arrow-width)*2));
  margin-left: var(--scroller-arrow-width);
  margin-right: var(--scroller-arrow-width);
}
.grid-scroller-items {
  display: flex;
  gap: 1rem;
  overflow: scroll;
  scroll-snap-type: x mandatory;
}
.grid-scroller-items > * {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.grid-scroller-scroller {
  position: relative;
}
.grid-scroller-arrow {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.grid-scroller-arrow-prev {
  left: 0;
}
.grid-scroller-arrow-next {
  right: 0;
}
.scroller-grid-toggle {
  font-family: $soft-font;
}
.grid-scroller.is-grid {
  // .grid-scroller-title,
  // .grid-scroller-items {
  //   margin-left: 0;
  //   margin-right: 0;
  //   width: 100%;
  // }
  .grid-scroller-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30ch,1fr));
  }
  .grid-scroller-arrow {
    display: none;
  }
}