// Blog
// ----------------------------------------------------------------

body.blog {

  .blog-banner {
    @include rhythm(0,.5,.5,0);
    box-shadow: 0 8px 0 rgba(0,0,0,.12);
    background: #e1e1e1 url('/images/pages/blog/banner-blog.png') 50% 50% no-repeat;
    background-size: cover;
    color: white;

    h1, h2 {
      color: white;
    }

    h1 {
      text-shadow: 0 2px 0 rgba(0,0,0,.2);
      width: 42%;
      margin: 0;
      font-size: clamp(1em,8.5vw,7em);
      line-height: 0.8;
    }

    h2 {
      margin: .5em 0 0;
      font-size: clamp(1em,1.5vw,1.5em);
      opacity: .8;
      display: none;

      @include breakpoint(min-width 880px) {
        display: block;
      }
    }
  }

  .content {
    @extend %med-type;

    @include breakpoint($lg) {
      padding-inline: 2em;
    }
  }

  .sidebar {
    background-color: #fff;

    @include breakpoint($lg) {
      @include leader(1);
      padding-inline: 2em;
      border-left: 1px solid palette(grey, light);
    }

    > ul {
      @include unstyled-list;

      > li {
        @include trailer(3);
      }
    }

    h2 {
      font-size: 1.3em;
    }
  }

  .page_title,
  .post > h2:first-child {
    @extend .heading-primary;
  }
  .post > h2:first-child {
    @include trailer(.25);
  }

  .postdate,
  .postmetadata {
    font-size: .8em;
    color: #b3b3b3;
    
    a {
      @include unstyled-link;
    }
  }

  .postmetadata {
    @include rhythm(.5,.5,0,1);
    border-top: 1px solid palette(grey, light);
  }

  .navigation {
    overflow: hidden;
    > div {
      float: left;
      width: 49%;

      & + div {
        float: right;
        text-align: right;
      }
    }
  }

}

// Utility Alignment Styles
// ----------------------------------------------------------------

.alignright {
  float:right;
  margin: 0 0 1em 1em;
}
.alignleft {
  float:left;
  margin: 0 1em 1em 0;
}
.aligncenter {
  display:block;
  margin:0 auto;
}
