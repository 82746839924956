$gap: 1rem !default;

// Box grids
// ----------------------------------------------------------------

$grid-item-width       : 275px; // collapses to 1 per row on mobile, up to 4 across on desktop
$grid-small-item-width : 150px; // collapses to 2 per row on mobile
$grid-med-item-width   : 215px; // collapses to 1 per row on mobile, up to 5 across on desktop
$grid-large-item-width : 340px; // 1 per row on mobile, 2 per row on desktop

// Flexible grid allows for varying number of columns
// min grid item width: 300px, collapses to 1 per row on mobile
.grid {
  --grid-item-width: #{$grid-item-width};
  --gap: #{$gap};

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
  gap: var(--gap);

  > * {
    margin: 0;
    // height: 100%; // ensure all sub-items fill grid space
  }
}

.grid-small { --grid-item-width: #{$grid-small-item-width}; }
.grid-med   { --grid-item-width: #{$grid-med-item-width}; }
.grid-large { --grid-item-width: #{$grid-large-item-width}; }


@include breakpoint($med) {
  .grid-2up.grid-inner-borders {
    grid-gap: 0;

    > *,
    .grid-item {
      border-right: 2px solid #dedede;
      border-top: 2px solid #dedede;
      padding: 2rem;

      &:nth-child(2n) { border-right: none; }
      &:nth-child(1),
      &:nth-child(2) { border-top: none; }
    }
  }
}